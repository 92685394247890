import http from './axios';

// 查询文件空间数据
export const getFileList = ({ pageNo, pageSize, name }) => {
    return http.get('/fileSpace/spacePage', {
        pageNo: pageNo,
        pageSize: pageSize,
        name: name,
    })
};

// 新增
export const addFile = ({ name, code, enabled, expireSeconds }) => {
    return http.json_post('/fileSpace/addSpace', {
        name: name,
        code: code,
        enabled: enabled,
        expireSeconds: expireSeconds,
    })
};

// 更新
export const editFile = ({ id, name, code, enabled, expireSeconds }) => {
    return http.json_post('/fileSpace/editSpace', {
        id: id,
        name: name,
        code: code,
        enabled: enabled,
        expireSeconds: expireSeconds,
    })
};

// 文件空间详情
export const spaceDetailList = ({ pageNo, pageSize, spaceId }) => {
    return http.get('/fileSpace/spaceDetailList', {
        pageNo: pageNo,
        pageSize: pageSize,
        spaceId: spaceId,
    })
};