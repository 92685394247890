<template>
  <div class="HadoopDistributedFile">
    <div class="searchCondition">
      <div class="searchConditionItem">
        名称：
        <a-input
          placeholder="请输入名称"
          v-model.trim="name"
          @keyup.enter="query"
          allowClear
          @change="allowClearChange"
        ></a-input>
      </div>
      <div class="searchConditionItem">
        路径：<template v-for="(item, index) in pathArr">
          <template v-if="index !== pathArr.length - 1">
            <a-button type="link" @click="skipDirectory(item)">
              {{ item }}
              /
            </a-button>
          </template>
          <template v-else>
            {{ item }}
          </template>
        </template>
      </div>
    </div>
    <div class="searchButton">
      <a-button type="primary" @click="query" icon="search">查询</a-button>
      <a-button type="primary" @click="addDirectory" icon="plus"
        >新建文件夹</a-button
      >
      <a-button type="primary" @click="uploadFile" icon="upload"
        >上传文件</a-button
      >
      <a-dropdown>
        <a-button type="primary"> 移动文件 <a-icon type="down" /> </a-button>
        <a-menu slot="overlay">
          <a-menu-item :disabled="selectedRowKeys.length == 0" @click="cut">
            <a-button
              type="link"
              style="padding: 0"
              :disabled="selectedRowKeys.length == 0"
            >
              剪切
            </a-button>
          </a-menu-item>
          <a-menu-item :disabled="cutList.length == 0" @click="cancelCut">
            <a-button
              type="link"
              style="padding: 0"
              :disabled="cutList.length == 0"
            >
              取消剪切
            </a-button>
          </a-menu-item>
          <a-menu-item :disabled="cutList.length == 0" @click="paste">
            <a-button
              type="link"
              style="padding: 0"
              :disabled="cutList.length == 0"
            >
              粘贴
            </a-button>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
    <a-table
      size="small"
      class="fileList"
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :loading="loadingTable"
      :rowKey="(record) => record.path"
      :row-class-name="getRowClassName"
      :row-selection="{
        onChange: onSelectChange,
        selectedRowKeys: selectedRowKeys,
      }"
    >
      <span slot="name" slot-scope="text, record" style="width: 100%">
        <a-button type="link" style="padding: 0" @click="clickName(record)">
          {{ text }}
        </a-button>
      </span>
      <span slot="isDirectory" slot-scope="text, record" style="width: 100%">
        <template v-if="record.isDirectory"> 文件夹 </template>
        <template v-if="record.isFile"> 文件 </template>
      </span>
      <span slot="permission" slot-scope="text, record" style="width: 100%">
        <a-popover
          v-model="record.permissionVisible"
          trigger="click"
          placement="right"
        >
          <template #content>
            <a-table
              size="small"
              class="fileList"
              :components="$common.getTitle(permissionColumns)"
              :columns="permissionColumns"
              :dataSource="record.permissionList"
              :pagination="false"
              :rowKey="(record) => record.key"
            >
              <span slot="read" slot-scope="text, record" style="width: 100%">
                <a-checkbox v-model="record.read"></a-checkbox>
              </span>
              <span slot="write" slot-scope="text, record" style="width: 100%">
                <a-checkbox v-model="record.write"></a-checkbox>
              </span>
              <span
                slot="execute"
                slot-scope="text, record"
                style="width: 100%"
              >
                <a-checkbox v-model="record.execute"></a-checkbox>
              </span>
            </a-table>
            <div style="margin-top: 10px; text-align: right">
              <a-button @click="query()" size="small">取消</a-button>
              <a-button
                type="primary"
                @click="editPermission(record)"
                size="small"
                style="margin-left: 10px"
              >
                确定
              </a-button>
            </div>
          </template>
          <a-button type="link" style="padding: 0">{{ text }}</a-button>
        </a-popover>
      </span>
      <span slot="owner" slot-scope="text, record, index" style="width: 100%">
        <template v-if="record.ownerVisible">
          <a-input v-model="record.owner" style="width: 150px" />
          <a-button
            type="link"
            class="button"
            style="padding: 0; margin-left: 5px"
            @click.stop="editOwnerOrGroup(record, 'owner')"
          >
            <a-icon type="check" />
          </a-button>
          <a-button
            type="link"
            class="button"
            style="padding: 0; margin-left: 5px"
            @click.stop="query()"
          >
            <a-icon type="close" />
          </a-button>
        </template>
        <template v-else>
          {{ text }}
          <a-button
            type="link"
            class="button"
            @click.stop="record.ownerVisible = true"
            style="padding: 0"
          >
            <a-icon type="edit" />
          </a-button>
        </template>
      </span>
      <span slot="group" slot-scope="text, record" style="width: 100%">
        <template v-if="record.groupVisible">
          <a-input v-model="record.group" style="width: 150px" />
          <a-button
            type="link"
            class="button"
            style="padding: 0; margin-left: 5px"
            @click.stop="editOwnerOrGroup(record, 'group')"
          >
            <a-icon type="check" />
          </a-button>
          <a-button
            type="link"
            class="button"
            style="padding: 0; margin-left: 5px"
            @click.stop="query()"
          >
            <a-icon type="close" />
          </a-button>
        </template>
        <template v-else>
          {{ text }}
          <a-button
            type="link"
            class="button"
            @click.stop="record.groupVisible = true"
            style="padding: 0"
          >
            <a-icon type="edit" />
          </a-button>
        </template>
      </span>
      <span slot="replication" slot-scope="text, record" style="width: 100%">
        <template v-if="record.replicationVisible">
          <a-input v-model="record.replication" style="width: 60px" />
          <a-button
            type="link"
            class="button"
            style="padding: 0; margin-left: 5px"
            @click.stop="editReplication(record)"
          >
            <a-icon type="check" />
          </a-button>
          <a-button
            type="link"
            class="button"
            style="padding: 0; margin-left: 5px"
            @click.stop="query()"
          >
            <a-icon type="close" />
          </a-button>
        </template>
        <template v-else>
          {{ text }}
          <a-button
            type="link"
            class="button"
            @click.stop="record.replicationVisible = true"
            v-if="record.isFile && !record.isDirectory"
            style="padding: 0"
          >
            <a-icon type="edit" />
          </a-button>
        </template>
      </span>
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">是否确认删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>

    <a-modal
      title="新建文件夹"
      v-model="addDirectoryShow"
      :maskClosable="false"
      width="500px"
    >
      <a-input
        :addon-before="path"
        v-model="directoryName"
        placeholder="请输入文件夹名称"
      ></a-input>
      <a-form style="margin-top: 10px">
        <a-form-item
          label="用户:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select v-model="user" placeholder="请选择用户" allowClear>
            <a-select-option
              v-for="(item, index) in userList"
              :key="index"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addDirectoryShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="addDirectory_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <a-modal
      title="上传文件"
      v-model="uploadFileShow"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="上传文件:">
          <a-upload-dragger
            :fileList="fileList"
            :remove="handleRemove"
            :beforeUpload="beforeUpload"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">单击或拖动文件到此区域进行上传</p>
            <p class="ant-upload-hint">只支持单个上传</p>
          </a-upload-dragger>
        </a-form-item>
        <a-form-item label="用户:">
          <a-select v-model="user" placeholder="请选择用户" allowClear>
            <a-select-option
              v-for="(item, index) in userList"
              :key="index"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="备注:">
          <a-textarea
            v-model="noteAdd"
            placeholder="请输入备注"
            :auto-size="{ minRows: 6, maxRows: 6 }"
          ></a-textarea>
        </a-form-item>
      </a-form>

      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="uploadFileShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="uploadFile_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/HadoopDistributedFile.js";
export default {
  name: "HadoopDistributedFile",
  data() {
    return {
      loadingTable: false,
      tableColumns: [
        {
          title: "名称",
          dataIndex: "name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "类型",
          dataIndex: "isDirectory",
          scopedSlots: { customRender: "isDirectory" },
        },
        {
          title: "文件权限",
          dataIndex: "permission",
          scopedSlots: { customRender: "permission" },
        },
        {
          title: "文件所有者",
          dataIndex: "owner",
          scopedSlots: { customRender: "owner" },
          width: 220,
        },
        {
          title: "所有者分组",
          dataIndex: "group",
          scopedSlots: { customRender: "group" },
          width: 220,
        },
        {
          title: "文件大小",
          dataIndex: "size",
        },
        {
          title: "最后更新时间",
          dataIndex: "lastModified",
        },
        {
          title: "文件的复制因子",
          dataIndex: "replication",
          scopedSlots: { customRender: "replication" },
          width: 150,
        },
        {
          title: "文件块大小",
          dataIndex: "blockSize",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 60,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      permissionColumns: [
        {
          title: "名称",
          dataIndex: "name",
          align: "center",
        },
        {
          title: "可读",
          dataIndex: "read",
          scopedSlots: { customRender: "read" },
          align: "center",
        },
        {
          title: "可写",
          dataIndex: "write",
          scopedSlots: { customRender: "write" },
          align: "center",
        },
        {
          title: "可执行",
          dataIndex: "execute",
          scopedSlots: { customRender: "execute" },
          align: "center",
        },
      ],
      cutList: [],
      name: "",
      path: "",
      selectedRowKeys: [],
      selectedRows: [],
      directoryName: "",
      addDirectoryShow: false,
      uploadFileShow: false,
      user: undefined,
      userList: [],
      ownerList: [],
      fileList: [],
      noteAdd: "",
    };
  },
  computed: {
    cutListInclude() {
      return (record) => {
        let arr = this.cutList.filter((item) => {
          return item.path == record.path;
        });
        if (arr.length > 0) {
          return true;
        }
        return false;
      };
    },
    pathArr() {
      let arr = this.path.split("/").filter((item) => {
        return item !== "";
      });
      arr.unshift("root");
      return arr;
    },
  },
  mounted() {
    this.query();
    this.getUserList();
  },
  methods: {
    // 上传文件数据处理
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      this.fileList = this.fileList.slice(-1);
      return false;
    },
    uploadFile_submit() {
      this.$message.loading({ content: "上传中", key: "uploadFile" });
      let data = {
        file: this.fileList[0],
        path: this.path,
        user: this.user,
        note: this.noteAdd,
      };
      api
        .uploadFile(data)
        .then((res) => {
          if (res.result == 200) {
            this.$message.success({ content: "上传成功", key: "uploadFile" });
            this.name = "";
            this.uploadFileShow = false;
            this.query();
          }
        })
        .catch(() => {
          this.uploadFileShow = false;
        });
    },
    getUserList() {
      api.getUserList().then((res) => {
        if (res.result == 200) {
          this.userList = res.data;
        }
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query();
    },
    skipDirectory(item) {
      if (item == "root") {
        this.path = "/";
      } else {
        this.path = this.path.split(item)[0] + item;
      }
      this.query();
    },
    query() {
      this.loadingTable = true;
      if (this.path == "") {
        this.path = "/";
      }
      let data = {
        name: this.name,
        path: this.path,
      };
      api.getFileList(data).then((res) => {
        if (res.result === 200) {
          res.data.forEach((item) => {
            item.path =
              (this.path == "/" ? this.path : this.path + "/") + item.name;
            item.permissionVisible = false;
            item.ownerVisible = false;
            item.groupVisible = false;
            item.replicationVisible = false;
            let permission = item.permission;
            if (permission[0] == "d") {
              permission = permission.substring(1);
            }
            item.permissionList = this.getArrayFromString(permission);
          });
          this.tableDataSource = res.data;
          this.loadingTable = false;
        }
      });
    },
    getArrayFromString(str) {
      let objArray = [];

      let read1 = false,
        write1 = false,
        execute1 = false,
        read2 = false,
        write2 = false,
        execute2 = false,
        read3 = false,
        write3 = false,
        execute3 = false;

      if (str[0] === "r") {
        read1 = true;
      }
      if (str[1] === "w") {
        write1 = true;
      }
      if (str[2] === "x") {
        execute1 = true;
      }
      if (str[3] === "r") {
        read2 = true;
      }
      if (str[4] === "w") {
        write2 = true;
      }
      if (str[5] === "x") {
        execute2 = true;
      }
      if (str[6] === "r") {
        read3 = true;
      }
      if (str[7] === "w") {
        write3 = true;
      }
      if (str[8] === "x") {
        execute3 = true;
      }

      objArray.push({
        key: 1,
        name: "所属人",
        read: read1,
        write: write1,
        execute: execute1,
      });
      objArray.push({
        key: 2,
        name: "所属组",
        read: read2,
        write: write2,
        execute: execute2,
      });
      objArray.push({
        key: 3,
        name: "其它",
        read: read3,
        write: write3,
        execute: execute3,
      });

      return objArray;
    },
    editPermission(record) {
      let permission = "";
      record.permissionList.forEach((item) => {
        let permissionItem = 0;
        if (item.read) {
          permissionItem += 4;
        }
        if (item.write) {
          permissionItem += 2;
        }
        if (item.execute) {
          permissionItem += 1;
        }
        permission = permission + permissionItem;
      });
      let data = {
        path: record.path,
        permission,
      };
      api.modifyPermission(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("修改成功");
          this.query();
        }
      });
    },
    editOwnerOrGroup(record, type) {
      let data = {
        path: record.path,
      };
      if (type == "owner") {
        data.owner = record.owner;
      } else if (type == "group") {
        data.group = record.group;
      }
      api.modifyOwner(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("修改成功");
          this.query();
        }
      });
    },
    editReplication(record) {
      let data = {
        path: record.path,
        replication: record.replication,
      };
      api.modifyReplication(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("修改成功");
          this.query();
        }
      });
    },
    getRowClassName(record, index) {
      if (this.cutListInclude(record)) {
        return "ant-table-row-selected";
      }
      return "";
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    addDirectory() {
      this.directoryName = "";
      if (this.ownerList.length > 0) {
        this.user = this.ownerList[this.ownerList.length - 1];
      } else {
        this.user = this.userList[0];
      }
      this.addDirectoryShow = true;
    },
    addDirectory_submit() {
      let data = {
        path: this.path,
        name: this.directoryName,
        user: this.user,
      };
      api.newDirectory(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("创建成功");
          this.name = "";
          this.addDirectoryShow = false;
          this.query();
        }
      });
    },
    uploadFile() {
      if (this.ownerList.length > 0) {
        this.user = this.ownerList[this.ownerList.length - 1];
      } else {
        this.user = this.userList[0];
      }
      this.noteAdd = "";
      this.uploadFileShow = true;
    },
    cut() {
      this.cutList.push(...this.selectedRows);
      this.selectedRows.splice(0);
      this.selectedRowKeys.splice(0);
    },
    cancelCut() {
      this.cutList.splice(0);
    },
    paste() {
      this.$axios
        .all(
          this.cutList.map((item) => {
            let data = {
              path: item.path,
              toPath: this.path,
            };
            return api.moveFile(data);
          })
        )
        .then((resArr) => {
          this.$message.success("移动成功");
          this.query();
          this.cancelCut();
        });
    },
    clickName(record) {
      if (record.isDirectory) {
        this.path = record.path;
        this.ownerList.push(record.owner);
        this.name = "";
        this.query();
      } else if (record.isFile) {
        this.downloadFile(record);
      }
    },
    // 点击下载
    downloadFile(record) {
      let href =
        location.protocol +
        process.env.VUE_APP_BASE_API +
        "/hadoop/downloadFile?path=" +
        record.path;
      window.location.href = href;
    },
    deleteClick(record) {
      let data = {
        path: record.path,
      };
      api.deleteFileOrDir(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("删除成功");
          this.name = "";
          this.query();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.HadoopDistributedFile {
  &::v-deep .ant-table-row-selected {
    td {
      background-color: #efefef !important;
    }
    &:hover td {
      background-color: #efefef !important;
    }
  }
}
</style>
