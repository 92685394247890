import http from './axios';

// 查询分享列表数据
export const shareList = ({ pageNo, pageSize, uuid, code }) => {
    return http.get('/fileSpace/spaceFileList', {
        pageNo: pageNo,
        pageSize: pageSize,
        uuid: uuid,
        code: code
    })
};