<template>
  <div class="download">
    <div class="download-form" v-if="!isForm">
      <div class="form-content">
        <h1>文件过期了</h1>
        <div class="file-content-2"></div>
      </div>
    </div>
    <div class="download-form" v-if="isForm">
      <div class="form-content" v-if="!isDownload">
        <h1>下载文件</h1>
        <div class="pwd" v-if="isPassword">
          <a-input-search
            placeholder="请输入密码"
            @search="onUnlock"
            enterButton="解锁"
            size="large"
            type="password"
          />
        </div>
        <div class="file-content" v-if="!isPassword">
          <div class="file">
            <a-icon type="file-text" class="file-text" />
            <span class="file-title">{{ shareFile.fileName }}</span>
            <a-progress v-if="isProgress" :percent="progressNum" />
          </div>
          <a-button type="primary" class="btn-download" @click="dowmloadFile"
            >下载</a-button
          >
        </div>
      </div>
      <div class="form-content" v-if="isDownload">
        <h1>下载完毕</h1>
        <div class="file-content-2"></div>
      </div>
    </div>
  </div>
</template>
<script>
import * as api from "../lib/download.js";
export default {
  name: "download",
  data() {
    return {
      progressNum: 0,
      isProgress: false,
      isForm: false,
      isPassword: false,
      isDownload: false,
      uuid: "",
      checkUuid: "",
      password: "",
      shareFile: {},
    };
  },
  mounted() {
    let url = window.location.href;
    let index = url.indexOf("#");
    let shearUrl = url.substring(index + 1);
    let paramsList = shearUrl.split("/");
    this.uuid = paramsList[0];
    this.checkUuid = paramsList[1];
    this.query();
  },
  methods: {
    // 查询
    query() {
      let data = {
        uuid: this.uuid,
        checkUuid: this.checkUuid,
      };
      api.shareList(data).then((res) => {
        this.isForm = false;
        if (res.result === 200) {
          let fileInfo = res.data;
          if (!fileInfo) {
            this.isForm = false;
          } else {
            this.isForm = true;
          }
          if (fileInfo && fileInfo.needPassword) {
            this.isPassword = true;
          } else {
            this.isPassword = false;
          }
          this.shareFile = fileInfo;
        }
      });
    },
    // 点击返回
    goBack() {
      let href = "https://file.huiyu.org.cn/shareList";
      window.location.href = href;
    },
    // 点击解锁
    onUnlock(value) {
      this.password = value;
      let data = {
        uuid: this.uuid,
        checkUuid: this.checkUuid,
        downloadPassword: this.password,
      };
      api.checkPassword(data).then((res) => {
        if (res.result === 200) {
          if (res.data.valid) {
            this.isPassword = false;
          } else {
            this.$message.warning("密码不正确");
          }
        }
      });
      // if (this.shareFile.pwd === value) {
      //   this.isPassword = false;
      // } else {
      //   this.$message.warning('密码不正确');
      // }
    },
    // 点击下载
    dowmloadFile() {
      let data = {
        uuid: this.uuid,
        checkUuid: this.checkUuid,
        downloadPassword: this.password,
      };
      let url = "";
      for (let i in data) {
        if (data[i]) {
          url += i + "=" + data[i] + "&";
        }
      }
      let href =
        location.protocol +
        process.env.VUE_APP_BASE_API +
        "/file/downloadShareFile?" +
        url.substr(0, url.length - 1);
      window.location.href = href;
      this.isProgress = true;
      this.$http
        .get_progress("/file/downloadShareFile", data, this.progressBar)
        .then((res) => {});
    },
    // 获取百分比（此处保留了一位小数）
    progressBar(progressEvent) {
      let percentage = Number(
        ((progressEvent.loaded / progressEvent.total) * 100).toFixed(1)
      );
      this.progressNum = percentage;
      if (percentage === 100) {
        this.isDownload = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.download {
  background-image: url(../assets/bg.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
  background-color: #f9f9fa;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .download-form {
    min-width: 786px;
    max-width: 1024px;
    min-height: 512px;
    max-height: 672px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 16px;
    padding: 32px;
    box-shadow: 0 12px 18px 2px rgba(34, 0, 51, 0.04),
      0 6px 22px 4px rgba(7, 48, 114, 0.12),
      0 6px 10px -4px rgba(14, 13, 26, 0.12);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .form-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      width: 640px;

      .pwd {
        width: 512px;

        span {
          width: 100%;
        }
      }

      .file-content-2 {
        width: 480px;
        text-align: center;

        .btn-download {
          width: 100%;
          font-size: 16px;
          margin-top: 16px;
          padding: 16px 25px;
          height: 50px;
          border-radius: 8px;
        }

        .btn-w {
          width: 50% !important;
        }
      }

      .file-content {
        width: 480px;

        .file {
          position: relative;
          padding: 12px 24px;
          border-radius: 4px;
          border: 1px solid #d7d7db;

          .file-text {
            font-size: 40px;
            color: rgb(22, 55, 202);
          }

          .file-title {
            position: absolute;
            top: 12px;
            left: 64px;
            font-size: 16px;
          }
        }

        .btn-download {
          width: 100%;
          font-size: 16px;
          margin-top: 16px;
          padding: 16px 25px;
          height: 50px;
          border-radius: 8px;
        }
      }

      h1 {
        text-align: center;
        font-size: 32px;
        margin-bottom: 16px;
        font-weight: 700;
      }

      p {
        text-align: center;
        color: #2a2a2e;
        line-height: 1.5;
        font-size: 16px;
      }
    }
  }
}
</style>
