import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import fileList from "./components/fileList.vue";
import shareList from "./components/shareList.vue";
import download from "./views/download.vue";
import fileDownload from "./views/fileDownload.vue";
import fileSpace from "./components/fileSpace.vue";
import vodImageDirectory from "./components/vodImageDirectory.vue";
import HadoopDistributedFile from "./components/HadoopDistributedFile.vue";
import dataNodeInfoList from "./components/dataNodeInfoList.vue";
import ossManagement from "./components/ossManagement.vue";
import ossFileList from "./components/ossFileList.vue";
import ossConfigList from "./components/ossConfigList.vue";
import ftpAccount from "./components/ftpAccount.vue";
import ftpAccountList from "./components/ftpAccountList";
import ftpServerList from "./components/ftpServerList";
import accountFile from "./components/accountFile";

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      redirect: "/fileList",
      components: {
        first: Home,
      },
      children: [
        {
          path: "/fileList",
          name: "fileList",
          components: {
            second: fileList,
          },
        },
        {
          path: "/shareList",
          name: "shareList",
          components: {
            second: shareList,
          },
        },
        {
          path: "/fileSpace",
          name: "fileSpace",
          components: {
            second: fileSpace,
          },
        },
        {
          path: "/vodImageDirectory",
          name: "vodImageDirectory",
          components: {
            second: vodImageDirectory,
          },
        },
        {
          path: "/ossManagement",
          name: "ossManagement",
          redirect: "/ossManagement/ossFileList",
          components: {
            second: ossManagement,
          },
          children: [
            {
              path: "/ossManagement/ossFileList",
              name: "ossFileList",
              components: {
                three: ossFileList,
              },
            },
            {
              path: "/ossManagement/ossConfigList",
              name: "ossConfigList",
              components: {
                three: ossConfigList,
              },
            },
          ],
        },
        {
          path: "/ftpAccount",
          name: "ftpAccount",
          redirect: "/ftpAccount/ftpServerList",
          components: {
            second: ftpAccount,
          },
          children: [
            {
              path: "/ftpAccount/ftpAccountList",
              name: "ftpAccountList",
              components: {
                three: ftpAccountList,
              },
            },
            {
              path: "/ftpAccount/ftpServerList",
              name: "ftpServerList",
              components: {
                three: ftpServerList,
              },
            },
          ],
        },
        {
          path: "/accountFile",
          name: "accountFile",
          components: {
            second: accountFile,
          },
        },
      ],
    },
    {
      path: "/download",
      name: "download",
      components: {
        first: download,
      },
    },
    {
      path: "/fileDownload",
      name: "fileDownload",
      components: {
        first: fileDownload,
      },
    },
  ],
});
