<template>
  <div class="ftpAccountList">
    <div class="searchCondition">
      <div class="searchConditionItem">
        服务器：
        <a-select
          v-model="searchFtpServerId"
          placeholder="请选择服务器"
          :filter-option="false"
          @search="ftpServerIdSarch"
          allowClear
        >
          <a-select-option v-for="item in ftpServerIdList" :key="item.id">{{
            item.name
          }}</a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        账号：
        <a-input
          placeholder="请输入账号名称"
          v-model.trim="searchAccessKey"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="addPublicKey" icon="plus"
          >新增</a-button
        >
      </div>
    </div>
    <a-table
      size="small"
      :scroll="{ x: true }"
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDateSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      :rowKey="(record) => record.id"
    >
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="goAccount(record)">文件列表</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="copy(record)">复制密码</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="edit(record)">编辑</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">是否确认删除{{ record.accessKey }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      class="action-class"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="ftp服务器:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="title === '新增'"
          required
        >
          <a-select
            v-model="ftpServerId"
            showSearch
            placeholder="请选择ftp服务器"
            :filter-option="false"
            @search="ftpServerIdSarch"
            allowClear
            @change="selectChange"
          >
            <a-select-option v-for="item in ftpServerIdList" :key="item.id">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="bucketName:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="type === 'S3'"
          required
        >
          <a-input v-model="bucketName" placeholder="请输入bucketName" />
        </a-form-item>
        <a-form-item
          label="账号:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="title === '新增'"
          required
        >
          <a-input v-model="accessKey" placeholder="请输入账号" />
        </a-form-item>
        <a-form-item
          label="密码:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="title === '新增'"
          required
        >
          <a-input v-model="accessSecret" placeholder="请输入密码" />
          <a href="javascript:;" @click="randomPswAdd" class="randomPswAdd"
            >随机密码</a
          >
        </a-form-item>
        <a-form-item
          label="初始化路径:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="initalPath" placeholder="请输入初始化路径" />
        </a-form-item>
        <a-form-item
          label="备注:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="remark" placeholder="请输入备注" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/ftpAccountList.js";

export default {
  name: "ftpAccountList",
  data() {
    return {
      title: "",
      accessKey: "",
      accessSecret: "",
      bucketName: "",
      initalPath: "",
      remark: "",
      ftpServerId: undefined,
      ftpServerIdList: [],
      searchFtpServerId: undefined,
      searchAccessKey: "",
      accountId: "",
      // -----------------------------
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "服务器名称",
          ellipsis: true,
          dataIndex: "serverName",
          width: 200,
        },
        {
          title: "账号",
          ellipsis: true,
          dataIndex: "accessKey",
          width: 150,
        },
        {
          title: "bucketName",
          ellipsis: true,
          dataIndex: "bucketName",
        },
        {
          title: "初始化路径",
          ellipsis: true,
          dataIndex: "initalPath",
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 150,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDateSource: [],
      tablePagination: {},
      loadingTable: false,
      tableData: [],
      type: "",
    };
  },
  mounted() {
    this.query(1);
    this.getServerList();
  },
  methods: {
    //随机密码-新增账号
    randomPswAdd() {
      let psw = this.$common.randomStr(false, 12);
      this.accessSecret = psw;
    },
    // 复制密码
    copy(val) {
      let data = {
        id: val.id,
        ftpServerId: val.ftpServerId,
      };
      let parameter = {
        ip: "",
        username: val.accessKey,
        password: "",
        initalPath: val.initalPath,
      };
      let dbName = val.serverName;
      let message = ""; // copy内容
      api
        .getPassword(data)
        .then((res) => {
          if (res.result === 200) {
            parameter.ip = res.data.ip;
            parameter.password = res.data.password;
            message = this.$common.copyInfo(parameter, dbName); // 复制数据库信息
            this.$copyText(message).then(
              (e) => {
                this.$message.success("复制成功");
              },
              (e) => {
                this.$message.error("复制失败");
              }
            );
          }
        })
        .catch((err) => {});
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        ftpServerType: "FTP",
        pageNo: this.pageNo,
        pageSize: 10,
        ftpServerId: this.searchFtpServerId,
        accessKey: this.searchAccessKey,
      };
      this.getFtpList(data);
    },
    // 查询账号列表
    getFtpList(data) {
      this.loadingTable = true;
      api
        .ftpList(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDateSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 服务器下拉框发生变化
    ftpServerIdSarch() {
      //   this.getServerList();
    },
    // 查询服务器列表
    getServerList() {
      let data = {
        serverType: "FTP",
      };
      api.serverList(data).then((res) => {
        if (res.result === 200) {
          this.ftpServerIdList = res.data;
        }
      });
    },
    // 新增
    addPublicKey() {
      this.addKeyVisible = true;
      this.title = "新增";
      this.getServerList();
      this.ftpServerId = undefined;
      this.accessKey = "";
      this.accessSecret = "";
      this.bucketName = "";
      this.initalPath = "";
      this.remark = "";
    },
    // 编辑
    edit(val) {
      this.addKeyVisible = true;
      this.title = "编辑";
      this.getServerList();
      this.ftpServerId = val.ftpServerId;
      this.accessKey = val.accessKey;
      this.accessSecret = val.accessSecret;
      this.bucketName = val.bucketName;
      this.initalPath = val.initalPath;
      this.remark = val.remark;
      this.accountId = val.id;
      this.type = val.serverType;
    },
    // 确定新增
    add_submit() {
      this.loading = true;
      if (this.title === "新增") {
        let data = {
          ftpServerId: this.ftpServerId,
          accessKey: this.accessKey,
          accessSecret: this.accessSecret,
          bucketName: this.bucketName,
          initalPath: this.initalPath,
          remark: this.remark,
        };
        if (!data.ftpServerId) {
          this.$message.warning("请选择服务器");
          return;
        }
        if (!data.accessKey) {
          this.$message.warning("请输入账号");
          return;
        }
        if (!data.accessSecret) {
          this.$message.warning("请输入密码");
          return;
        }
        if (this.type === "S3") {
          if (!data.bucketName) {
            this.$message.warning("请输入bucketName");
            return;
          }
        }
        // console.log(data)
        api.ftpAdd(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.loading = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        let data = {
          id: this.accountId,
          initalPath: this.initalPath,
          remark: this.remark,
          bucketName: this.bucketName,
        };
        api.editAccount(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.loading = false;
            this.$message.success("编辑成功");
            this.query();
          }
        });
      }
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.type = "";
    },
    // 新增-服务器下拉框发生改变
    selectChange(val) {
      this.tableData = this.ftpServerIdList.filter((el) => el.id === val);
      this.type = this.tableData[0].type;
      console.log(this.type);
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.ftpDelete(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    // 点击账号列表
    goAccount(val) {
      this.$router.replace({
        path: "/accountFile",
        query: {
          path: val.initalPath,
          ftpAccountId: val.id,
          type: val.serverType,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.randomPswAdd {
  position: absolute;
  right: -66px;
  top: -10px;
}
</style>
