import http from "./axios";

export const getFileList = (data) => {
  return http.get("/oss/fileList", data);
};

export const getBucketNameList = (data) => {
  return http.get("/oss/bucketNameList", data);
};

export const createDir = (data) => {
  return http.post("/oss/createDir", data);
};

export const uploadFile = (data) => {
  return http.files("/oss/uploadFile", data);
};

export const rename = (data) => {
  return http.post("/oss/rename", data);
};

export const download = (data) => {
  return http.blobDownload("/oss/download", data);
};

export const batchDeleteFile = (data) => {
  return http.json_post("/oss/batchDeleteFile", data);
};
