<template>
  <div
    class="fileList"
    ref="fileList"
    @dragenter.prevent="handleDragenter"
    @dragleave.prevent="handleDragleave"
    @dragover.prevent="handleDragover"
    @drop.prevent="handleDrop"
  >
    <div class="mask" v-show="maskShow">拖拽至此上传文件</div>
    <div class="searchCondition">
      <div class="searchConditionItem">
        文件名称：
        <a-input
          placeholder="请输入文件名称"
          v-model.trim="fileName"
          @keyup.enter="query(1)"
          allowClear
          @change="allowClearChange"
        ></a-input>
      </div>
      <div class="searchConditionItem">
        上传人：
        <a-select
          v-model="uploader"
          placeholder="请选择上传人"
          allowClear
          showSearch
          @change="query(1)"
        >
          <a-select-option
            v-for="(item, index) in uploaderList"
            :key="index"
            :value="item"
          >
            {{ item }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        存储服务器：
        <a-input
          placeholder="请输入存储服务器"
          v-model.trim="fileIp"
          @keyup.enter="query(1)"
          allowClear
          @change="allowClearChange"
        ></a-input>
      </div>
      <div class="searchConditionItem">
        备注：
        <a-input
          placeholder="请输入备注"
          v-model.trim="note"
          @keyup.enter="query(1)"
          allowClear
          @change="allowClearChange"
        ></a-input>
      </div>
      <div class="searchConditionItem">
        <span class="search-name">文件大小：</span>
        <a-input
          v-model.trim="fileSizeMin"
          placeholder="最小值"
          class="search-size-input"
          @keyup.enter="query(1)"
          allowClear
          @change="allowClearChange"
        >
          <a-select
            slot="addonAfter"
            v-model="fileSizeMinUnit"
            style="width: 55px"
          >
            <a-select-option value="K">K</a-select-option>
            <a-select-option value="M">M</a-select-option>
            <a-select-option value="G">G</a-select-option>
          </a-select>
        </a-input>
        <i style="display: inline-block; margin-left: 20px"></i>
        <a-input
          v-model.trim="fileSizeMax"
          placeholder="最大值"
          class="search-size-input"
          @keyup.enter="query(1)"
          allowClear
          @change="allowClearChange"
        >
          <a-select
            slot="addonAfter"
            v-model="fileSizeMaxUnit"
            style="width: 55px"
          >
            <a-select-option value="K">K</a-select-option>
            <a-select-option value="M">M</a-select-option>
            <a-select-option value="G">G</a-select-option>
          </a-select>
        </a-input>
      </div>
      <div class="searchConditionItem">
        <div class="date-time">
          上传时间:
          <a-date-picker
            :disabledDate="disabledLastLoginStartDate"
            format="YYYY-MM-DD"
            v-model="lastLoginDateStart"
            placeholder="开始日期"
            @openChange="handleLastLoginStartOpenChange"
          />
          <i style="display: inline-block; margin-left: 20px"></i>
          <a-date-picker
            :disabledDate="disabledLastLoginEndDate"
            format="YYYY-MM-DD"
            placeholder="结束日期"
            v-model="lastLoginDateEnd"
            :open="loginEndOpen"
            @openChange="handleLastLoginEndOpenChange"
          />
        </div>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="addFile" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      size="small"
      :components="$common.getTitle(columns)"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="pagination"
      :rowKey="(record) => record.id"
      :row-selection="{
        onChange: onSelectChange,
        selectedRowKeys: selectedRowKeys,
      }"
    >
      <span slot="expired" slot-scope="text, record" style="width: 100%">
        <a-tag v-if="text" color="red">已过期</a-tag>
        <a-tag v-if="!text" color="green"> 未过期 </a-tag>
      </span>
      <span slot="fileName" slot-scope="text, record" style="width: 100%">
        <a href="javascript:;" @click="download(record)">{{ text }}</a>
        <a-tooltip v-if="record.encrypted">
          <template slot="title"> 已加密 </template>
          <img
            src="../../public/suo.png"
            alt=""
            width="15px"
            style="margin-left: 10px"
          />
        </a-tooltip>
      </span>
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a href="javascript:;" @click="shareCreate(record)">创建分享</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="spaceClick(record)">上传空间</a>
        <a-divider type="vertical" />
        <a-dropdown>
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            其它 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a
                href="javascript:;"
                @click="checkFileContent(record)"
                :disabled="
                  record.fileType !== 'txt' &&
                  record.fileType !== 'xlsx' &&
                  record.fileType !== 'csv' &&
                  record.fileType !== 'log'
                "
                >查看</a
              >
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="editNote(record)">修改备注</a>
            </a-menu-item>
            <a-menu-item>
              <a
                href="javascript:;"
                @click="jumpAnchorUrl(record)"
                :disabled="!record.anchorUrl"
                >查看关联详情</a
              >
            </a-menu-item>
            <a-menu-item>
              <a-popconfirm
                placement="right"
                okText="确认"
                cancelText="取消"
                @confirm="deleteFile(record)"
              >
                <template slot="title"
                  >确认是否删除{{ record.fileName }}</template
                >
                <a href="javascript:;" style="color: #ff4d4f">删除</a>
              </a-popconfirm>
            </a-menu-item>
            <a-menu-item>
              <a
                href="javascript:;"
                @click="copyPath(record)"
                :disabled="!record.path"
                >复制路径</a
              >
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>

    <a-popconfirm
      placement="right"
      okText="确认"
      cancelText="取消"
      @confirm="batchDeleteFile"
      :disabled="selectedRowKeys.length == 0"
    >
      <template slot="title">确认是否删除</template>
      <a-button
        type="danger"
        icon="delete"
        :disabled="selectedRowKeys.length == 0"
        >批量删除</a-button
      >
    </a-popconfirm>
    <!-- 新增 -->
    <a-modal :title="title" v-model="visibleAddFile" :maskClosable="false">
      <a-form>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="上传文件:"
          v-if="title == '新增'"
        >
          <div class="dropbox">
            <a-upload-dragger
              :fileList="uploadFileList"
              :remove="handleRemove"
              :beforeUpload="beforeUpload"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox" />
              </p>
              <p class="ant-upload-text">单击或拖动文件到此区域进行上传</p>
              <p class="ant-upload-hint">只支持单个上传</p>
            </a-upload-dragger>
          </div>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="过期时间:"
          class="expired"
          v-if="title == '新增'"
        >
          <a-switch
            checked-children="永久"
            un-checked-children="六个月"
            v-model="expired"
          />
        </a-form-item>
        <a-form-item
          label="备注:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-textarea
            v-model="noteAdd"
            placeholder="请输入备注"
            :auto-size="{ minRows: 6, maxRows: 6 }"
          ></a-textarea>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="visibleAddFile = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="updateFile_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 创建分享 -->
    <a-modal
      title="创建分享"
      v-model="visibleCreateShare"
      :maskClosable="false"
      :after-close="closeCreateShare"
    >
      <a-form>
        <a-form-item
          label="文件名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <span>{{ shareFileName }}</span>
        </a-form-item>
        <a-form-item
          label="过期时间:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-date-picker
            :disabledDate="disabledExpirationTime"
            format="YYYY-MM-DD"
            v-model="dateTime"
            placeholder="过期时间"
            class="date_style"
          />
        </a-form-item>
        <a-form-item
          label="密码保护:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-switch
            checked-children="开启"
            un-checked-children="关闭"
            :checked="checkedPasswd"
            @change="onChangePasswd"
          />
          <a-input
            v-if="isPassword"
            placeholder="请输入密码"
            v-model="password"
            class="input-share"
          ></a-input>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="visibleCreateShare = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="createShareOk"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 创建成功后复制 -->
    <a-modal title="复制链接" v-model="visibleCopy" width="800px">
      <div>
        页面下载：<span class="hidden">{{ ModalText }}</span>
        <a-button type="primary" v-copy="ModalText">复制</a-button>
      </div>
      <div style="margin-top: 20px" v-if="needPassword">
        直接下载：<span class="hidden">{{ ModalUrl }}</span>
        <a-button type="primary" v-copy="ModalUrl">复制</a-button>
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="submit" type="primary" @click="visibleCopy = false"
            >关闭</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 上传空间弹框 -->
    <a-modal
      title="上传空间"
      v-model="visiblespace"
      :maskClosable="false"
      :after-close="closespace"
    >
      <a-form>
        <a-form-item
          label="文件空间:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select v-model="spaceId" placeholder="请选择文件空间" allowClear>
            <a-select-option v-for="item in spaceList" :key="item.id">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="visiblespace = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="space_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal title="复制路径" v-model="copyPathShow" :maskClosable="false">
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="文件路径:">
          <a-input v-model="path" disabled />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="submit" type="primary" v-copy="path">复制</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      :maskClosable="false"
      width="700px"
      title="查看文件"
      v-model="checkFileContentShow"
      :footer="null"
    >
      <a-table
        size="small"
        v-if="fileType == 'xlsx' || fileType == 'xls'"
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(excelFileColumns)"
        :columns="excelFileColumns"
        :dataSource="excelFileList"
        :pagination="false"
        :rowKey="(record) => record.id"
      ></a-table>

      <a-textarea
        v-else
        v-model="fileContent"
        :rows="20"
        style="margin-bottom: 10px"
      />

      <h4
        style="margin: 0 0 10px 0"
        v-if="
          fileContent.split('\n').length == 500 || excelFileList.length == 500
        "
      >
        最多只能显示五百行
      </h4>
    </a-modal>
  </div>
</template>

<script>
import * as api from "../lib/fileList.js";
import moment from "moment";
export default {
  name: "fileList",
  data() {
    return {
      ModalText: "",
      ModalUrl: "",
      visibleCopy: false,
      noteAdd: "",
      note: "",
      uploader: undefined,
      uploaderList: [],
      uploadFileList: [],
      fileName: "",
      checkedPasswd: false,
      isPassword: false,
      password: "",
      dateTime: null,
      visibleCreateShare: false,
      columns: [
        {
          title: "序号",
          dataIndex: "id",
          width: 70,
        },
        {
          title: "文件名称",
          dataIndex: "fileName",
          scopedSlots: { customRender: "fileName" },
          width: 290,
          ellipsis: true,
        },
        {
          title: "文件大小",
          dataIndex: "fileSizeString",
          width: 90,
        },
        {
          title: "上传人",
          dataIndex: "uploader",
          width: 100,
          ellipsis: true,
        },
        {
          title: "上传时间",
          dataIndex: "gmtCreated",
          width: 160,
        },
        {
          title: "是否过期",
          dataIndex: "expired",
          width: 90,
          scopedSlots: { customRender: "expired" },
        },
        {
          title: "备注",
          dataIndex: "note",
          width: 300,
          ellipsis: true,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          width: 220,
          scopedSlots: { customRender: "action" },
        },
      ],
      dataSource: [],
      pagination: {},
      selectedRowKeys: [],
      fileName: "",
      visibleAction: false,
      fileList: [],
      loading: false,
      pageNo: 1,
      visibleAddFile: false,
      fileId: "",
      shareFileName: "",
      fileIp: "",
      fileSizeMin: "",
      fileSizeMax: "",
      fileSizeMinUnit: "M",
      fileSizeMaxUnit: "M",
      lastLoginDateStart: null,
      lastLoginDateEnd: null,
      loginEndOpen: false,
      spaceList: [],
      visiblespace: false,
      spaceId: undefined,
      expired: false,
      expiredSeconds: "",
      needPassword: false,
      title: "",
      maskShow: false,
      syncLoading: null,
      copyPathShow: false,
      path: "",
      fileType: "txt",
      checkFileContentShow: false,
      fileContent: "",
      excelFileList: [],
      excelFileColumns: [],
    };
  },
  computed: {
    startTime() {
      // 上传开始时间
      if (this.lastLoginDateStart) {
        return this.$common
          .transformTime(this.lastLoginDateStart)
          .substr(0, 10);
      }
      return null;
    },
    endTime() {
      // 上传结束时间
      if (this.lastLoginDateEnd) {
        return this.$common.transformTime(this.lastLoginDateEnd).substr(0, 10);
      }
      return null;
    },
    minSize() {
      // 文件最小值
      if (this.fileSizeMin) {
        if (this.fileSizeMinUnit === "K") {
          return this.fileSizeMin * 1024;
        }
        if (this.fileSizeMinUnit === "M") {
          return this.fileSizeMin * 1024 * 1024;
        }
        if (this.fileSizeMinUnit === "G") {
          return this.fileSizeMin * 1024 * 1024 * 1024;
        }
      }
      return null;
    },
    maxSize() {
      // 文件最大值
      if (this.fileSizeMax) {
        if (this.fileSizeMaxUnit === "K") {
          return this.fileSizeMax * 1024;
        }
        if (this.fileSizeMaxUnit === "M") {
          return this.fileSizeMax * 1024 * 1024;
        }
        if (this.fileSizeMaxUnit === "G") {
          return this.fileSizeMax * 1024 * 1024 * 1024;
        }
      }
      return null;
    },
  },
  mounted() {
    let nowdate = new Date();
    let pastTime = new Date(nowdate.getTime() + 30 * 24 * 3600 * 1000);
    pastTime.setHours(23);
    pastTime.setMinutes(59);
    pastTime.setSeconds(59);
    pastTime.setMilliseconds(59);
    this.dateTime = moment(pastTime, "YYYY-MM-DD HH:mm:ss");
    this.getUploaderList();
    this.query(1);
  },
  methods: {
    getUploaderList() {
      api.getUploaderList().then((res) => {
        if (res.result == 200) {
          this.uploaderList = res.data;
        }
      });
    },
    handleDragenter(e) {
      if (!this.$refs.fileList.contains(e.relatedTarget)) {
        this.maskShow = true;
      }
    },
    handleDragleave(e) {
      if (!this.$refs.fileList.contains(e.relatedTarget)) {
        this.maskShow = false;
      }
    },
    handleDragover(e) {},
    async handleDrop(e) {
      let fileList = await this.$common.dragFileList(e);

      let length = fileList.length;
      if (length > 0) {
        this.syncLoading = this.$syncLoading({
          message: `文件上传中(0/${length})...`,
          progressShow: true,
          percent: 0,
        });
        let index = 0;

        this.uploadFile(fileList, index, length);
      }
      this.maskShow = false;
    },
    uploadFile(fileList, index, length) {
      let data = {
        file: fileList[index],
        expiredSeconds: 15552000,
        note: "",
      };
      api
        .uploadFile(data, (progressEvent) => {
          const percent = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.syncLoading.update({
            percent,
          });
        })
        .then((res) => {
          this.uploadFileNext(fileList, index, length);
        })
        .catch(() => {
          this.uploadFileNext(fileList, index, length);
        });
    },
    uploadFileNext(fileList, index, length) {
      this.syncLoading.update({
        message: `文件上传中(${index + 1}/${length})...`,
      });

      if (index + 1 < length) {
        this.syncLoading.update({
          percent: 0,
        });
        setTimeout(() => {
          this.uploadFile(fileList, index + 1, length);
        }, 500);
      } else {
        setTimeout(() => {
          this.syncLoading.close();
          this.query();
        }, 1000);
      }
    },
    // 点击上传空间
    spaceClick(val) {
      this.getSpaceList();
      this.visiblespace = true;
      this.fileId = val.id;
    },
    closespace() {
      this.spaceId = undefined;
    },
    space_submit() {
      let data = {
        fileId: this.fileId,
        spaceId: this.spaceId,
      };
      api.intoSpace(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("提交成功");
          this.visiblespace = false;
        }
      });
    },
    // 文件空间列表
    getSpaceList() {
      // /fileSpace/spaceList
      api.spaceList().then((res) => {
        if (res.result === 200) {
          this.spaceList = res.data;
        }
      });
    },
    // 不可选过期时间
    disabledExpirationTime(current) {
      return current && current < moment().endOf("day");
    },
    // 创建分享
    shareCreate(val) {
      this.visibleCreateShare = true;
      this.fileId = val.id;
      this.shareFileName = val.fileName;
    },
    // 确定创建分享
    createShareOk() {
      let expireDate = null;
      if (this.dateTime) {
        expireDate = this.$common.transformTime(this.dateTime).substr(0, 10);
        expireDate = expireDate + " 23:59:59";
      }
      let data = {
        fileName: this.shareFileName,
        fileId: this.fileId,
        expireDate: expireDate,
      };
      if (this.checkedPasswd) {
        data.downloadPassword = this.password;
      }
      this.loading = true;
      api.addShare(data).then((res) => {
        this.loading = false;
        if (res.result === 200) {
          this.visibleCreateShare = false;
          this.$message.success("创建成功");
          this.needPassword = !res.data.needPassword;
          this.visibleCopy = true;
          this.ModalText = `https://file.huiyu.org.cn/download#${res.data.uuid}/${res.data.checkUuid}`;
          this.ModalUrl = `https://file.t4g.cn/api/file/downloadShareFile?uuid=${res.data.uuid}&checkUuid=${res.data.checkUuid}&downloadPassword=`;
        }
      });
    },
    // 关闭创建分享
    closeCreateShare() {
      this.fileList = [];
      this.checkedPasswd = false;
      this.isPassword = false;
      this.password = "";
      // this.dateTime = null;
      this.shareFileName = "";
    },
    // 密码保护
    onChangePasswd(checked) {
      this.checkedPasswd = checked;
      this.isPassword = checked;
    },
    // 选择登录日期
    handleLastLoginStartOpenChange(open) {
      if (!open) {
        this.loginEndOpen = true;
      }
    },
    handleLastLoginEndOpenChange(open) {
      this.loginEndOpen = open;
    },
    // 不可选的登录日期
    disabledLastLoginStartDate(startValue) {
      const endValue = this.lastLoginDateEnd;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledLastLoginEndDate(endValue) {
      const startValue = this.lastLoginDateStart;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 查询文件表格数据
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: index || this.pageNo,
        pageSize: 10,
        key: this.fileName,
        minSize: this.minSize,
        maxSize: this.maxSize,
        startTime: this.startTime,
        endTime: this.endTime,
        fileIp: this.fileIp,
        note: this.note,
        uploader: this.uploader,
      };
      this.getFileListAnalysis(data);
    },
    // 获取文件表数据
    getFileListAnalysis(data) {
      api.getFileList(data).then((res) => {
        if (res.result == 200) {
          let list = res.data.records;
          list.forEach((item, i) => {
            item.key = i + 1;
            switch (item.uploadMethod) {
              case "MANUAL_UPLOAD":
                item.uploadMethodLabel = "手动上传";
                break;
              case "SCAN":
                item.uploadMethodLabel = "扫描上传";
                break;
              case "TASK_UPLOAD":
                item.uploadMethodLabel = "定时任务上传";
                break;
              case "INTERFACE":
                item.uploadMethodLabel = "接口上传";
                break;
              default:
                item.uploadMethodLabel = "";
                break;
            }
          });
          this.dataSource = list;
          this.pagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.changePage(current),
          };
        }
      });
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 分页查询
    changePage(current) {
      this.pageNo = current;
      this.query();
    },
    // 点击下载
    download(val) {
      let data = {
        id: val.id,
        fileName: encodeURI(val.fileName),
        fileIp: val.fileIp,
        path: val.path,
      };
      let url = "";
      for (let i in data) {
        if (data[i]) {
          url += i + "=" + data[i] + "&";
        }
      }
      let href =
        location.protocol +
        process.env.VUE_APP_BASE_API +
        "/file/downloadExcel?" +
        url.substr(0, url.length - 1);
      window.location.href = href;
    },
    // 点击新增
    addFile() {
      this.title = "新增";
      this.noteAdd = "";
      this.expired = false;
      this.uploadFileList.splice(0);
      this.visibleAddFile = true;
    },
    editNote(val) {
      this.title = "修改";
      this.fileId = val.id;
      this.noteAdd = val.note;
      this.visibleAddFile = true;
    },
    checkFileContent(val) {
      this.fileContent = "";
      this.excelFileList.splice(0);
      this.excelFileColumns.splice(0);
      this.fileType = val.fileType;
      this.syncLoading = this.$syncLoading({
        message: "获取文件内容中...",
      });
      this.$http
        .get("/file/checkFileContent", {
          fileId: val.id,
        })
        .then((res) => {
          if (res.result == 200) {
            this.syncLoading.close();
            if (res.data.state == "ARCHIVED") {
              this.$message.error("文件已归档，查看需要进行解冻");
            } else if (res.data.state == "SUCCESS") {
              if (this.fileType == "xlsx" || this.fileType == "xls") {
                res.data.data.forEach((item, index) => {
                  let obj = {
                    id: index + 1,
                  };
                  item.forEach((value, key) => {
                    obj["column" + (key + 1)] = value;
                  });
                  this.excelFileList.push(obj);
                });

                res.data.data[0].forEach((value, index) => {
                  this.excelFileColumns.push({
                    title: "列" + (index + 1),
                    ellipsis: true,
                    dataIndex: "column" + (index + 1),
                    width: 100,
                  });
                });
              } else {
                this.fileContent = res.data.data[0].join("\n");
              }
              this.checkFileContentShow = true;
            }
          }
        })
        .catch((err) => {
          this.syncLoading.close();
        });
    },
    // 确定新增
    updateFile_submit() {
      this.loading = true;
      let data = {
        note: this.noteAdd,
      };
      if (this.title == "新增") {
        if (this.uploadFileList.length < 1) {
          this.$message.warning("请上传文件");
          this.loading = false;
          return;
        }
        if (this.expired) {
          data.expiredSeconds = -1;
        } else {
          data.expiredSeconds = 15552000;
        }
        data.file = this.uploadFileList[0];
        this.syncLoading = this.$syncLoading({
          message: `文件上传中...`,
          progressShow: true,
          percent: 0,
        });
        api
          .uploadFile(data, (progressEvent) => {
            const percent = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            this.syncLoading.update({
              percent,
            });
          })
          .then((res) => {
            if (res.result == 200) {
              this.syncLoading.close();
              this.uploadFileList.splice(0);
              this.loading = false;
              this.$message.success("上传成功");
              this.query();
              this.visibleAddFile = false;
            }
          })
          .catch(() => {
            this.loading = false;
            this.query();
            this.syncLoading.close();
          });
      } else {
        data.id = this.fileId;
        api.editNote(data).then((res) => {
          if (res.result == 200) {
            this.loading = false;
            this.$message.success("修改成功");
            this.query();
            this.visibleAddFile = false;
          }
        });
      }
    },
    // 上传处理
    handleRemove(file) {
      const index = this.uploadFileList.indexOf(file);
      const newFileList = this.uploadFileList.slice();
      newFileList.splice(index, 1);
      this.uploadFileList = newFileList;
    },
    beforeUpload(file) {
      this.uploadFileList = [...this.uploadFileList, file];
      return false;
    },
    jumpAnchorUrl(record) {
      window.open(record.anchorUrl);
    },
    // 点击删除文件
    deleteFile(val) {
      api.deleteFile({ id: val.id }).then((res) => {
        if (res.result == 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    batchDeleteFile() {
      this.$axios
        .all(
          this.selectedRowKeys.map((id) => {
            let data = {
              id,
            };
            return api.deleteFile(data);
          })
        )
        .then((resArr) => {
          this.$message.success("删除成功");
          this.query();
        });
    },
    copyPath(val) {
      this.path = val.path;
      this.copyPathShow = true;
    },
  },
};
</script>

<style scoped lang="scss">
.date_style {
  width: 310px;
}
.input-share {
  width: 245px;
  margin-left: 10px;
}
.hidden {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 80%;
  margin-right: 10px;
}
.expired {
  ::v-deep .ant-switch {
    background-color: #00d300;
  }
  ::v-deep .ant-switch-checked {
    background-color: #1890ff !important;
  }
}
.fileList {
  position: relative;

  .mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    color: #fff;
    font-size: 30px;
    display: flex;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
  }
}
</style>
