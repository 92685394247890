import http from "./axios";

// 查询列表
const queryPage = (data) => {
  return http.get("/vod/vodDirectoryPage", data);
};

// 添加
const addData = (data) => {
  return http.json_post("/vod/addVodDirectory", data);
};

// 编辑
const editData = (data) => {
  return http.json_post("/vod/editVodDirectory", data);
};

const getDetailList = (data) => {
  return http.get("/vod/vodImagePage", data);
};

const deleteData = (data) => {
  return http.json_post("/vod/deleteVodDirectory", data);
};

const deleteImage = (data) => {
  return http.json_post("/vod/deleteImage", data);
};

const addVodImage = (data) => {
  return http.files("/vod/addVodImage", data);
};

export default {
  queryPage,
  addData,
  editData,
  getDetailList,
  deleteData,
  deleteImage,
  addVodImage,
};
