<template>
  <div class="fileSpace">
    <div class="searchCondition">
      <div class="searchConditionItem">
        任务名称：
        <a-input
          placeholder="请输入文件名称"
          v-model.trim="name"
          @keyup.enter="query(1)"
          allowClear
          @change="allowClearChange"
        ></a-input>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="fileAdd" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      size="small"
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :rowKey="(record) => record.id"
    >
      <span slot="enabled" slot-scope="text" style="width: 100%">
        <a-tag v-if="text" color="green">启用</a-tag>
        <a-tag v-if="!text" color="orange"> 禁用 </a-tag>
      </span>
      <span slot="expireSeconds" slot-scope="text" v-if="text > 0">
        {{ text / 24 / 3600 }}天
      </span>
      <span slot="expireSeconds" slot-scope="text" v-else> 不过期 </span>
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a href="javascript:;" @click="viewDetails(record)">查看详情</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="copyUrl(record)" v-copy="copyInfo"
          >复制链接</a
        >
        <a-divider type="vertical" />
        <a href="javascript:;" @click="editClick(record)">修改</a>
      </span>
    </a-table>
    <!-- 新增弹框 -->
    <a-modal
      :title="title"
      v-model="visibleAdd"
      :maskClosable="false"
      :after-close="closeAdd"
    >
      <a-form>
        <a-form-item
          label="名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="nameAdd" placeholder="请输入名称"></a-input>
        </a-form-item>
        <a-form-item
          label="编码:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="code" placeholder="请输入编码"></a-input>
        </a-form-item>
        <a-form-item
          label="启用:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            v-model="enabled"
          />
        </a-form-item>
        <a-form-item
          label="过期时长:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="expireSeconds"
            placeholder="请选择过期时长"
            allowClear
          >
            <a-select-option
              v-for="item in expireSecondsList"
              :key="item.key"
              >{{ item.value }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="visibleAdd = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="addFileOk"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 查看详情弹框 -->
    <a-modal
      :title="detailTitle"
      v-model="visibleDetail"
      :maskClosable="false"
      :after-close="closeDetail"
      :width="1200"
    >
      <a-table
        size="small"
        :components="$common.getTitle(detailColumns)"
        :columns="detailColumns"
        :dataSource="detailDataSource"
        :pagination="detailPagination"
      >
        <span slot="fileName" slot-scope="text, recoed">
          <a
            href="javascript:;"
            class="btn-download"
            @click="dowmloadFile(recoed)"
          >
            {{ text }}
          </a>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="visibleDetail = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/fileSpace.js";
export default {
  name: "fileSpace",
  data() {
    return {
      loading: false,
      tableColumns: [
        {
          title: "序号",
          dataIndex: "id",
          width: 60,
        },
        {
          title: "名称",
          dataIndex: "name",
        },
        {
          title: "编码",
          dataIndex: "code",
        },
        {
          title: "过期时长",
          dataIndex: "expireSeconds",
          scopedSlots: { customRender: "expireSeconds" },
        },
        {
          title: "状态",
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 60,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      pageNo: 1,
      name: "",
      visibleAdd: false,
      nameAdd: "",
      code: "",
      enabled: true,
      expireSeconds: undefined,
      title: "",
      editId: "",
      copyInfo: "", //复制链接
      path: location.protocol + process.env.VUE_APP_BASE_API,
      detailTitle: "",
      visibleDetail: false,
      detailColumns: [
        {
          title: "序号",
          dataIndex: "id",
          width: 60,
        },
        {
          title: "文件名",
          dataIndex: "fileName",
          scopedSlots: { customRender: "fileName" },
        },
        {
          title: "文件大小",
          dataIndex: "fileSize",
        },
        {
          title: "过期时间",
          dataIndex: "expireDate",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
        },
      ],
      detailDataSource: [],
      detailPagination: {},
      detailPageNo: 1,
      detailId: "",
      expireSecondsList: [
        { value: "1天", key: 86400 },
        { value: "2天", key: 172800 },
        { value: "3天", key: 259200 },
        { value: "7天", key: 604800 },
        { value: "15天", key: 1296000 },
        { value: "30天", key: 2592000 },
        { value: "不过期", key: -1 },
      ],
    };
  },
  computed: {},
  mounted() {
    this.query(1);
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: index || this.pageNo,
        pageSize: 10,
        name: this.name,
      };
      this.getFileList(data);
    },
    // 查询文件空间数据
    getFileList(data) {
      api.getFileList(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.tableDataSource = list;
          this.tablePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.changePage(current),
          };
        }
      });
    },
    // 分页
    changePage(index) {
      this.pageNo = index;
      this.query(index);
    },
    // 点击新增
    fileAdd() {
      this.visibleAdd = true;
      this.title = "新增";
    },
    // 点击修改
    editClick(val) {
      this.title = "编辑";
      this.visibleAdd = true;
      this.nameAdd = val.name;
      this.code = val.code;
      this.enabled = val.enabled;
      this.expireSeconds = val.expireSeconds;
      this.editId = val.id;
    },
    closeAdd() {
      this.nameAdd = "";
      this.code = "";
      this.enabled = true;
      this.expireSeconds = undefined;
    },
    // 新增确定
    addFileOk() {
      let data = {
        name: this.nameAdd,
        code: this.code,
        enabled: this.enabled,
        expireSeconds: this.expireSeconds,
      };
      if (this.title === "新增") {
        api.addFile(data).then((res) => {
          if (res.result === 200) {
            this.$message.success("添加成功");
            this.query();
            this.visibleAdd = false;
          }
        });
      } else {
        data.id = this.editId;
        api.editFile(data).then((res) => {
          if (res.result === 200) {
            this.$message.success("编辑成功");
            this.query();
            this.visibleAdd = false;
          }
        });
      }
    },
    // 查看详情
    viewDetails(val) {
      this.visibleDetail = true;
      this.detailTitle = "【" + val.name + "】详情";
      this.detailId = val.id;
      this.getDetail();
    },
    // 获取详情接口
    getDetail(index) {
      if (index) {
        this.detailPageNo = index;
      }
      let data = {
        pageNo: index || this.detailPageNo,
        pageSize: 10,
        spaceId: this.detailId,
      };
      api.spaceDetailList(data).then((res) => {
        if (res.result === 200) {
          this.detailDataSource = res.data.records;
          this.detailPagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.changePageDetail(current),
          };
        }
      });
    },
    changePageDetail(index) {
      this.detailPageNo = index;
      this.getDetail(index);
    },
    closeDetail() {
      this.detailDataSource = [];
    },
    // 复制链接
    copyUrl(val) {
      this.copyInfo =
        this.path + "/fileSpace/space?uuid=" + val.uuid + "&code=" + val.code;
    },
    // 点击下载
    dowmloadFile(item) {
      let data = {
        spaceDetailId: item.id,
      };
      let url = "";
      for (let i in data) {
        if (data[i]) {
          url += i + "=" + data[i] + "&";
        }
      }
      let href =
        location.protocol +
        process.env.VUE_APP_BASE_API +
        "/fileSpace/downloadSpaceFile?" +
        url.substr(0, url.length - 1);
      window.location.href = href;
      this.isProgress = true;
      this.$http
        .get_progress("/fileSpace/downloadSpaceFile", data, this.progressBar)
        .then((res) => {});
    },
  },
};
</script>
