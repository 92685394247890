import http from "./axios";

// 查询文件集合
export const ftpFileList = (data) => {
  return http.get("/ftpFile/list", data);
};

// 新建文件夹
export const mkdir = (data) => {
  return http.get("/ftpFile/mkdir", data);
};

// 重命名
export const rename = (data) => {
  return http.get("/ftpFile/rename", data);
};

// 上传文件
export const upload = (data) => {
  return http.files("/ftpFile/upload", data);
};

// 删除文件
export const ftpFileDelete = (data) => {
  return http.get("/ftpFile/delete", data);
};

// 分享
export const getFileShareLink = (data) => {
  return http.get("/ftpFile/getFileShareLink", data);
};
