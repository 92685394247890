<template>
  <div class="treeFile">
    <!-- 文件列表 -->
    <a-tree
      :load-data="onLoadData"
      :expanded-keys="expandedKeys"
      :auto-expand-parent="autoExpandParent"
      :tree-data="treeData"
      :show-icon="true"
      @expand="onExpand"
      @select="onSelect"
      class="file_tree_i_c"
      @rightClick="onRightClick"
    >
      <template slot="custom" slot-scope="item">
        <a-icon :type="item.icon" />
        <span class="blank"></span>
        <span class="remark">{{ item.privilege }}</span>
        <span v-if="item.isLeaf" class="fileLength">{{ item.fileLength }}</span>
      </template>
    </a-tree>
  </div>
</template>
<script>
export default {
  name: "treeFile",
  props: {
    treeData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loadUrl: {
      type: String,
      default: "",
    },
    ftpAccountId: {
      default: -1,
    },
  },
  data() {
    return {
      expandedKeys: [],
      autoExpandParent: true,
    };
  },
  mounted() {},
  methods: {
    // 展开/收起节点时触发
    onExpand(expandedKeys, e) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
      this.$emit("onExpand", expandedKeys, e);
    },
    // 点击文件列表
    onSelect(selectedKeys, info) {
      this.$emit("onSelect", info);
    },
    // 动态加载
    onLoadData(treeNode) {
      console.log("动态加载", treeNode);
      return new Promise((resolve) => {
        if (treeNode.dataRef.children) {
          resolve();
          return;
        }
        let data = {
          ftpAccountId: this.ftpAccountId,
          path: treeNode.eventKey,
        };
        this.$http
          .get(this.loadUrl, data)
          .then((res) => {
            if (res.result === 200) {
              this.$emit("onLoadData", treeNode, res.data);
              resolve();
            }
          })
          .catch((err) => {
            resolve();
          });
      });
    },
    onRightClick({ event, node }) {
      this.$emit("onRightClick", { event, node });
    },
  },
};
</script>
<style lang="scss" scoped>
.treeFile {
  height: 100%;
  width: 100%;
  .file_tree_i_c {
    overflow-y: auto;
    height: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .ant-tree li {
    .blank {
      margin-right: 600px;
    }
    .remark {
      margin-right: 100px;
    }
  }
}
</style>
