import http from "./axios";

// 查询文件列表数据
export const getFileList = (data) => {
  return http.get("/file/list", data);
};

// 修改文件
export const updateFile = (data) => {
  return http.json_post("/excelFile/updateFile", data);
};

// 新增文件
export const addFile = (data) => {
  return http.json_post("/excelFile/addFile", data);
};

export const uploadFile = (data, onUploadProgress) => {
  return http.files("/file/upload", data, onUploadProgress);
};

export const editNote = (data) => {
  return http.json_post("/file/editNote", data);
};

// 删除文件
export const deleteFile = (data) => {
  return http.get("/file/deleteFile", data);
};

// 创建分享
export const addShare = (data) => {
  return http.json_post("/file/shareFile", data);
};

// 文件空间
export const intoSpace = (data) => {
  return http.get("/file/intoSpace", data);
};

// 文件空间列表
export const spaceList = () => {
  return http.get("/fileSpace/spaceList");
};

export const getUploaderList = () => {
  return http.get("/file/getUploaderList");
};
