import Vue from "vue";

//封装公共代码
const common = {};

// 字节B转化成KB，MB，GB
common.byteConversion = (limit) => {
  var size = "";
  if (limit < 0.1 * 1024) {
    //小于0.1KB，则转化成B
    size = limit.toFixed(2) + "B";
  } else if (limit < 0.1 * 1024 * 1024) {
    //小于0.1MB，则转化成KB
    size = (limit / 1024).toFixed(2) + "KB";
  } else if (limit < 0.1 * 1024 * 1024 * 1024) {
    //小于0.1GB，则转化成MB
    size = (limit / (1024 * 1024)).toFixed(2) + "MB";
  } else {
    //其他转化成GB
    size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "GB";
  }

  var sizeStr = size + ""; //转成字符串
  var index = sizeStr.indexOf("."); //获取小数点处的索引
  var dou = sizeStr.substr(index + 1, 2); //获取小数点后两位的值
  if (dou == "00") {
    //判断后两位是否为00，如果是则删除00
    return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2);
  }
  return size;
};

// pt-online-schema-change生成
common.ptSchemaChange = ({ alter, host, port, sql, dbName }, tableName) => {
  let head = "pt-online-schema-change ",
    H = "--host=",
    uroot = "-uroot ",
    P = "-P",
    p = "-p$PWD ",
    A = "--alter ",
    D = "D=",
    t = "t=",
    tail = "--execute --charset=utf8 --no-check-alter",
    ptText = "";
  sql = sql.replace(/\"/g, "'"); // 双引号替换成单引号
  if (alter) {
    if (port === 3306) {
      ptText =
        head +
        H +
        host +
        " " +
        uroot +
        p +
        A +
        '"' +
        sql +
        '" ' +
        D +
        dbName +
        "," +
        t +
        "'" +
        tableName +
        "' " +
        tail;
    } else {
      ptText =
        head +
        H +
        host +
        " " +
        uroot +
        P +
        port +
        " " +
        p +
        A +
        '"' +
        sql +
        '" ' +
        D +
        dbName +
        "," +
        t +
        "'" +
        tableName +
        "' " +
        tail;
    }
  } else {
    if (port === 3306) {
      ptText =
        head +
        H +
        host +
        " " +
        uroot +
        p +
        '"' +
        sql +
        '" ' +
        D +
        dbName +
        "," +
        t +
        "'" +
        tableName +
        "' " +
        tail;
    } else {
      ptText =
        head +
        H +
        host +
        " " +
        uroot +
        P +
        port +
        " " +
        p +
        '"' +
        sql +
        '" ' +
        D +
        dbName +
        "," +
        t +
        "'" +
        tableName +
        "' " +
        tail;
    }
  }
  return ptText + "\n";
};

// 复制数据库密码信息
common.copyInfo = (parameter, dbName) => {
  let headBorder = "";
  let content = ""; // copy内容
  let paramsConent = []; // 内容长度
  let heatLengt = []; // 标题长度
  for (let item in parameter) {
    let str = parameter[item].toString();
    paramsConent.push(str.length);
    heatLengt.push(item.length);
  }
  paramsConent = paramsConent.sort((a, b) => b - a);
  heatLengt = heatLengt.sort((a, b) => b - a);
  // 表头绘制
  if (dbName.length < paramsConent[0] + heatLengt[0]) {
    let dValue = paramsConent[0] + heatLengt[0] + 2 - dbName.length;
    for (let j = 0; j < dValue; j++) {
      let blankSpace = " ";
      dbName += blankSpace;
    }
    // 边框绘制
    for (let y = 0; y < paramsConent[0] + heatLengt[0] + 4; y++) {
      let HLine = "-";
      headBorder += HLine;
    }
    headBorder = "+" + headBorder + "+";
  }
  // 表格内容绘制
  for (let i in parameter) {
    let heat = i;
    parameter[i] = parameter[i].toString();
    if (parameter[i].length < paramsConent[0]) {
      let dValue = paramsConent[0] - parameter[i].length;
      for (let k = 0; k < dValue; k++) {
        let blankSpace = " ";
        parameter[i] += blankSpace;
      }
    }
    // 绘制标题
    if (i.length < heatLengt[0]) {
      let dValue = heatLengt[0] - i.length;
      for (let k = 0; k < dValue; k++) {
        let blankSpace = " ";
        heat += blankSpace;
      }
    }
    if (parameter[i].trim()) {
      content += "| " + heat + ": " + parameter[i] + " |" + "\n";
    }
  }
  return (
    headBorder +
    "\n" +
    "| " +
    dbName +
    " |" +
    "\n" +
    headBorder +
    "\n" +
    content +
    headBorder
  );
};

//时间格式：yyyy-MM-dd HH:mm:ss
common.transformTime = function (time) {
  var time = new Date(time);
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  function add0(m) {
    return m < 10 ? "0" + m : m;
  }
  return (
    y +
    "-" +
    add0(m) +
    "-" +
    add0(d) +
    " " +
    add0(h) +
    ":" +
    add0(mm) +
    ":" +
    add0(s)
  );
};

common.dayFormat = (day) => {
  let date = new Date(day).toLocaleDateString().replace(/\//g, "-");
  date = date.split("-");
  date.forEach((element, index) => {
    if (element * 1 < 10) {
      date[index] = 0 + element;
    }
  });
  date = date.join("-");
  return date;
};

//Es6数组去重
common.unique = function (arr) {
  var x = new Set(arr);
  return [...x];
};

// 深度拷贝
common.deepClone = (source) => {
  const targetObj = source.constructor === Array ? [] : {}; // 判断复制的目标是数组还是对象
  for (const keys in source) {
    // 遍历目标
    if (source.hasOwnProperty(keys)) {
      if (source[keys] && typeof source[keys] === "object") {
        // 如果值是对象，就递归一下
        targetObj[keys] = source[keys].constructor === Array ? [] : {};
        targetObj[keys] = common.deepClone(source[keys]);
      } else {
        // 如果不是，就直接赋值
        targetObj[keys] = source[keys];
      }
    }
  }
  return targetObj;
};

// 随机生成数字+大小写字母
common.randomStr = function (randomFlag, min, max) {
  // randomFlag：是否任意长度
  // min：任意长度最小位[固定位数]
  // max：任意长度最大位
  //生成3-32位随机串：randomStr(true, 3, 32)
  //生成43位随机串：randomStr(false, 43)
  let str = "",
    range = min,
    index = null,
    arr = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

  if (randomFlag) {
    // 随机产生
    range = Math.round(Math.random() * (max - min)) + min;
  }

  for (let i = 0; i < range; i++) {
    index = Math.round(Math.random() * (arr.length - 1));
    str += arr[index];
  }
  return str;
};

//input只能输入数字和一个小数点
common.numberCheck = (num) => {
  var str = num;
  var len1 = str.substr(0, 1);
  var len2 = str.substr(1, 1);
  //如果第一位是0，第二位不是点，就用数字把点替换掉
  if (str.length > 1 && len1 == 0 && len2 != ".") {
    str = str.substr(1, 1);
  }
  //第一位不能是.
  if (len1 == ".") {
    str = "";
  }
  //限制只能输入一个小数点
  if (str.indexOf(".") != -1) {
    var str_ = str.substr(str.indexOf(".") + 1);
    if (str_.indexOf(".") != -1) {
      str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
    }
  }
  //正则替换，保留数字和小数点
  str = str.replace(/[^\d^\.]+/g, "");
  //如果需要保留小数点后两位，则用下面公式
  // str = str.replace(/\.\d\d$/,'')
  return str;
};

//防止多次点击
common.promiseDownload = (href) => {
  let p = new Promise((resolve, rejects) => {
    let a = false;
    window.location.href = href;
    resolve(a);
  });
  return p;
};

// 创建cookie
common.setCookie = (c_name, value, expiredays) => {
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  document.cookie =
    c_name +
    "=" +
    escape(value) +
    (expiredays == null ? "" : ";expires=" + exdate.toGMTString());
};

// 读取cookie
common.getCookie = (c_name) => {
  if (document.cookie.length > 0) {
    //先查询cookie是否为空，为空就return ""
    let c_start = document.cookie.indexOf(c_name + "="); //通过String对象的indexOf()来检查这个cookie是否存在，不存在就为 -1
    if (c_start != -1) {
      c_start = c_start + c_name.length + 1; //最后这个+1其实就是表示"="号啦，这样就获取到了cookie值的开始位置
      let c_end = document.cookie.indexOf(";", c_start); //其实我刚看见indexOf()第二个参数的时候猛然有点晕，后来想起来表示指定的开始索引的位置...这句是为了得到值的结束位置。因为需要考虑是否是最后一项，所以通过";"号是否存在来判断
      if (c_end == -1) c_end = document.cookie.length;
      return unescape(document.cookie.substring(c_start, c_end)); //通过substring()得到了值。想了解unescape()得先知道escape()是做什么的，都是很重要的基础，想了解的可以搜索下，在文章结尾处也会进行讲解cookie编码细节
    }
  }
  return "";
};

// 插入到光标位置
common.insertInputTxt = (insertText, id, textarea) => {
  let text = insertText;
  let areaField = document.getElementById(id); // 拿到目标标签
  // IE浏览器
  if (document.selection) {
    areaField.focus();
    let sel = document.selection.createRange();
    sel.text = text;
  } else if (areaField.selectionStart || areaField.selectionStart === 0) {
    // 谷歌 Firefox 等
    let startPos = areaField.selectionStart;
    let endPos = areaField.selectionEnd;
    let restoreTop = areaField.scrollTop; // 获取滚动条高度
    //  textarea 是v-model的值
    // text 是 选择的要插入的值
    textarea =
      textarea.substring(0, startPos) +
      text +
      textarea.substring(endPos, textarea.length);
    if (restoreTop > 0) {
      areaField.scrollTop = restoreTop;
    }
    areaField.focus();
    areaField.selectionStart = startPos + text.length;
    areaField.selectionEnd = startPos + text.length;
  } else {
    textarea += text;
    areaField.focus();
  }
  return textarea;
};

// 拖拽文件获取fileList
common.dragFileList = async (e) => {
  const items = [...e.dataTransfer.items];
  let fileList = [];
  const promises = [];

  for (let i = 0; i < items.length; i++) {
    const item = items[i].webkitGetAsEntry();
    if (item) {
      if (item.isFile) {
        promises.push(
          new Promise((resolve) => {
            item.file((file) => {
              fileList.push(file);
              resolve();
            });
          })
        );
      } else if (item.isDirectory) {
        promises.push(readDirectory(item, fileList));
      }
    }
  }

  await Promise.all(promises);
  return fileList;

  function readDirectory(directoryEntry, fileList) {
    return new Promise((resolve) => {
      const reader = directoryEntry.createReader();
      reader.readEntries((entries) => {
        const subPromises = [];
        for (let i = 0; i < entries.length; i++) {
          const entry = entries[i];
          if (entry.isFile) {
            subPromises.push(
              new Promise((resolve) => {
                entry.file((file) => {
                  fileList.push(file);
                  resolve();
                });
              })
            );
          } else if (entry.isDirectory) {
            // 递归读取子文件夹
            subPromises.push(readDirectory(item, fileList));
          }
        }
        Promise.all(subPromises).then(resolve);
      });
    });
  }
};

common.getTitle = (columns) => {
  const draggingMap = {};
  columns.forEach((col) => {
    const k = col.dataIndex || col.key;
    draggingMap[k] = col.width;
  });
  const draggingState = Vue.observable(draggingMap);
  const resizeableTitle = (h, props, children) => {
    let thDom = null;
    const { key, ...restProps } = props;
    let col;
    if (key == "selection-column") {
      return (
        <th {...restProps} class="resize-table-th">
          {children}
        </th>
      );
    } else {
      col = columns.find((item) => {
        const k = item.dataIndex || item.key;
        return k === key;
      });
    }
    if (col) {
      if (!col.width) {
        return <th {...restProps}>{children}</th>;
      }
      const onDrag = (x) => {
        draggingState[key] = 0;
        col.width = Math.max(x, 1);
      };
      const onDragstop = () => {
        draggingState[key] = thDom.getBoundingClientRect().width;
      };
      return (
        <th
          {...restProps}
          v-ant-ref={(r) => {
            thDom = r;
          }}
          width={draggingState[key]}
          class="resize-table-th"
        >
          {children}
          <vue-draggable-resizable
            key={col.dataIndex || col.key}
            class="table-draggable-handle"
            w={10}
            x={col.width || draggingState[key]}
            z={1}
            axis="x"
            draggable={true}
            resizable={false}
            onDragging={onDrag}
            onDragstop={onDragstop}
          ></vue-draggable-resizable>
        </th>
      );
    }
  };
  let components = {
    header: {
      cell: resizeableTitle,
    },
  };
  return components;
};

// 表格隔行变色
common.rowClassColor = (record, index) => {
  let className = "light-row";
  if (index % 2 === 1) className = "dark-row";
  return className;
};

export default common;
