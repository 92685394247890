import http from "./axios";

// 查询列表
export const ossConfigPage = (data) => {
  return http.get("/oss/ossConfigPage", data);
};

// 添加
export const addData = (data) => {
  return http.json_post("/oss/addOssConfig", data);
};

// 编辑
export const editData = (data) => {
  return http.json_post("/oss/editOssConfig", data);
};

// 删除
export const deleteData = (data) => {
  return http.json_post("/oss/deleteOssConfig", data);
};
