import http from "./axios";

// 查询服务器列表
export const ftpServeList = (data) => {
  return http.get("/ftp/listUserFtpServer", data);
};

// 新增服务器
export const addFtpServer = (data) => {
  return http.json_post("/ftp/addFtpServer", data);
};

// 服务器下拉框
export const typeList = () => {
  return http.get("/ftpFile/typeList");
};

// 编辑服务器
export const editFtpServer = (data) => {
  return http.json_post("/ftp/editFtpServer", data);
};

// 删除服务器
export const deleteFtpServer = (data) => {
  return http.json_post("/ftp/deleteFtpServer", data);
};

// 确定分享--新增
export const shareProject = (data) => {
  return http.json_post("/ftp/shareFptServer", data);
};

// 搜索分享名称
export const userList = (data) => {
  return http.get("/user/list", data);
};
