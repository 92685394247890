import http from "./axios";

export const getFileList = (data) => {
  return http.get("/hadoop/fileList", data);
};

export const getUserList = () => {
  return http.get("/hadoop/getUserList");
};

export const newDirectory = (data) => {
  return http.json_post("/hadoop/newDirectory", data);
};

export const uploadFile = (data) => {
  return http.files("/hadoop/uploadFile", data);
};

export const moveFile = (data) => {
  return http.json_post("/hadoop/moveFile", data);
};

export const deleteFileOrDir = (data) => {
  return http.json_post("/hadoop/deleteFileOrDir", data);
};

export const modifyPermission = (data) => {
  return http.json_post("/hadoop/modifyPermission", data);
};

export const modifyOwner = (data) => {
  return http.json_post("/hadoop/modifyOwner", data);
};

export const modifyReplication = (data) => {
  return http.json_post("/hadoop/modifyReplication", data);
};
