<template>
  <div class="dataNodeInfoList">
    <div class="searchButton">
      <a-button type="primary" @click="query()" icon="search">查询</a-button>
    </div>
    <a-table
      size="small"
      class="dataNodeInfoList"
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :loading="loadingTable"
      :rowKey="(record) => record.node"
    >
      <span slot="node" slot-scope="text, record" style="width: 100%">
        <a-icon
          type="check"
          style="color: #5fa341"
          v-if="record.status == 'In Service'"
        />
        <a-icon
          type="stop"
          style="color: #5fa341"
          v-if="record.status == 'Decommissioning'"
        />
        <a-icon
          type="stop"
          style="color: #eea236"
          v-if="record.status == 'Decommissioned'"
        />
        <a-icon
          type="tool"
          style="color: #5fa341"
          v-if="record.status == 'Entering Maintenance'"
        />
        <a-icon
          type="tool"
          style="color: #eea236"
          v-if="record.status == 'In Maintenance'"
        />
        {{ text }}
      </span>
      <span slot="capacityStr" slot-scope="text, record" style="width: 100%">
        <span style="display: inline-block; width: 100px">{{ text }}</span>
        <a-tooltip>
          <template slot="title">
            非dfs使用容量: {{ record.nonDFSUsedRate }}%<br />
            dfs使用容量: {{ record.usedRate }}%
          </template>
          <a-progress
            :percent="record.usedRate + record.nonDFSUsedRate"
            :success-percent="record.nonDFSUsedRate"
            size="small"
            :showInfo="false"
            style="width: 200px"
          />
        </a-tooltip>
      </span>
      <span slot="blockPoolUsed" slot-scope="text, record" style="width: 100%">
        {{ text }}({{ record.blockPoolUsedPercent }}%)
      </span>
      <a-table
        size="small"
        slot="expandedRowRender"
        slot-scope="record"
        :components="$common.getTitle(innerColumns)"
        :columns="innerColumns"
        :data-source="record.volumeInfoVoList"
        :pagination="false"
        :rowKey="(record) => record.directory"
      >
      </a-table>
    </a-table>
  </div>
</template>
<script>
import api from "../lib/dataNodeInfoList.js";
export default {
  name: "dataNodeInfoList",
  data() {
    return {
      loadingTable: false,
      tableColumns: [
        {
          title: "名称",
          dataIndex: "node",
          scopedSlots: { customRender: "node" },
        },
        {
          title: "上次心跳",
          dataIndex: "lastContact",
        },
        {
          title: "dfs使用容量",
          dataIndex: "usedStr",
        },
        {
          title: "非dfs使用容量",
          dataIndex: "nonDFSUsedStr",
        },
        {
          title: "总容量",
          dataIndex: "capacityStr",
          scopedSlots: { customRender: "capacityStr" },
        },
        {
          title: "块数量",
          dataIndex: "blocks",
        },
        {
          title: "块池使用容量",
          dataIndex: "blockPoolUsed",
          scopedSlots: { customRender: "blockPoolUsed" },
        },
      ],
      tableDataSource: [],
      innerColumns: [
        {
          title: "路径",
          dataIndex: "directory",
        },
        {
          title: "存储类型",
          dataIndex: "storageType",
        },
        {
          title: "使用容量",
          dataIndex: "usedSpace",
        },
        {
          title: "未使用容量量",
          dataIndex: "leftSpace",
        },
        {
          title: "预留容量",
          dataIndex: "reservedSpace",
        },
        {
          title: "副本预留容量",
          dataIndex: "reservedSpaceForReplicas",
        },
        {
          title: "块数量",
          dataIndex: "numBlocks",
        },
      ],
    };
  },
  mounted() {
    this.query();
  },
  methods: {
    query() {
      this.loadingTable = true;
      api.getDataNodeInfoList().then((res) => {
        if (res.result === 200) {
          res.data.forEach((item) => {
            item.blockPoolUsedPercent = item.blockPoolUsedPercent.toFixed(2);
            item.nonDFSUsedRate =
              ((item.nonDFSUsed / item.capacity) * 100).toFixed(2) * 1;
            item.usedRate = ((item.used / item.capacity) * 100).toFixed(2) * 1;
          });
          this.tableDataSource = res.data;
          this.loadingTable = false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.dataNodeInfoList {
  .dataNodeInfoList-search {
    position: relative;
    margin-bottom: 20px;
    .dataNodeInfoList-search-1 {
      float: left;
      margin-right: 20px;
    }
    .search-name {
      display: inline-block;
      line-height: 32px;
      width: 75px;
    }
    .search-size-input {
      width: 150px !important;
    }
    .backup {
      padding: 5px 0;
    }
    span {
      width: 240px;
    }
    input {
      width: 240px;
    }
    &::after {
      content: "";
      clear: both;
      display: block;
    }
  }
  .dataNodeInfoList-search-button {
    margin-bottom: 20px;
    button {
      margin-right: 20px;
    }
  }
}
</style>
