import http from './axios';

// 查询分享列表数据
export const shareList = ({ uuid, checkUuid }) => { 
  return http.get('/file/shareInfo', { uuid, checkUuid }) 
};

// 密码解锁
export const checkPassword = ({ uuid, checkUuid, downloadPassword }) => { 
  return http.json_post('/file/checkPassword', { uuid, checkUuid, downloadPassword }) 
};