<template>
  <div class="ftpServerList">
    <div class="searchCondition">
      <div class="searchConditionItem">
        ip：
        <a-input
          placeholder="请输入ip"
          v-model.trim="host"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        类型：
        <a-select v-model="ftpType" placeholder="请选择类型" allowClear>
          <a-select-option v-for="item in ftpTypeList" :key="item">{{
            item
          }}</a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        名称：
        <a-input
          placeholder="请输入名称"
          v-model.trim="name"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="addPublicKey" icon="plus"
          >新增</a-button
        >
      </div>
    </div>
    <a-table
      size="small"
      :scroll="{ x: true }"
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDateSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      :rowKey="(record) => record.id"
    >
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="share(record)">分享</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="edit(record)">编辑</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">是否确认删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      class="action-class"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input v-model="form.name" placeholder="请输入名称" />
        </a-form-item>
        <a-form-item
          label="类型:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-select
            v-model="form.type"
            placeholder="请选择类型"
            allowClear
            :disabled="title === '编辑'"
          >
            <a-select-option v-for="item in ftpTypeList" :key="item">{{
              item
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="外网ip:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input
            v-model="form.host"
            placeholder="请输入外网ip"
            :disabled="title === '编辑'"
          />
        </a-form-item>
        <a-form-item
          label="内网ip:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="form.type !== 'S3'"
        >
          <a-input
            v-model="form.innerHost"
            placeholder="请输入内网ip"
            :disabled="title === '编辑'"
          />
        </a-form-item>
        <a-form-item
          label="容器:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="form.type !== 'S3'"
        >
          <a-input
            v-model="form.containerId"
            placeholder="请输入容器"
            :disabled="title === '编辑'"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 分享  -->
    <a-modal
      title="分享项目"
      v-model="visibleShared"
      :maskClosable="false"
      :afterClose="closeShared"
    >
      <a-form>
        <a-form-item>
          <a-select
            mode="multiple"
            :value="searchIdList"
            style="width: 100%"
            :filterOption="false"
            @search="searchUserName"
            @change="handleChange"
            placeholder="请输入关键词"
          >
            <a-select-option v-for="i in searchList" :key="i.loginName">{{
              i.userName
            }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="sharedCancel">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="sharedOk"
            >提交</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/ftpServerList.js";

export default {
  name: "ftpServerList",
  data() {
    return {
      ftpType: undefined,
      ftpTypeList: [],
      //
      title: "",
      form: {
        name: "",
        host: "",
        containerId: "",
        id: "",
        innerHost: "",
        type: "",
      },
      host: "",
      name: "",
      visibleShared: false,
      searchIdList: [],
      searchList: [],
      shareId: "",
      //   oldSearchIdS: [],
      // -----------------------------
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
        },
        {
          title: "外网ip",
          ellipsis: true,
          dataIndex: "host",
        },
        {
          title: "内网ip",
          ellipsis: true,
          dataIndex: "innerHost",
        },
        {
          title: "容器",
          ellipsis: true,
          dataIndex: "containerId",
        },
        {
          title: "类型",
          ellipsis: true,
          dataIndex: "type",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 150,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDateSource: [],
      tablePagination: {},
      loadingTable: false,
    };
  },
  mounted() {
    this.query(1);
    this.getTypeList();
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        // ftpServerType: "FTP",
        pageNo: this.pageNo,
        pageSize: 10,
        host: this.host,
        name: this.name,
        type: this.ftpType,
      };
      this.getFtpServerList(data);
    },
    // 查询账号列表
    getFtpServerList(data) {
      this.loadingTable = true;
      api
        .ftpServeList(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDateSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 服务器下拉框
    getTypeList() {
      api.typeList().then((res) => {
        if (res.result === 200) {
          this.ftpTypeList = res.data;
        }
      });
    },
    // 新增
    addPublicKey() {
      this.addKeyVisible = true;
      this.title = "新增";
      this.form.name = "";
      this.form.host = "";
      this.form.innerHost = "";
      this.form.containerId = "";
      this.form.type = undefined;
    },
    // 编辑
    edit(val) {
      this.addKeyVisible = true;
      this.title = "编辑";
      this.form.name = val.name;
      this.form.host = val.host;
      this.form.innerHost = val.innerHost;
      this.form.containerId = val.containerId;
      this.form.type = val.type;
      this.form.id = val.id;
    },
    // 确定新增
    add_submit() {
      if (this.title == "新增") {
        let data = {
          name: this.form.name,
          host: this.form.host,
          innerHost: this.form.innerHost,
          containerId: this.form.containerId,
          type: this.form.type,
        };
        if (!data.name) {
          this.$message.warning("请输入名称");
          return;
        }
        if (!data.type) {
          this.$message.warning("请选择类型");
          return;
        }
        if (!data.host) {
          this.$message.warning("请输入外网ip");
          return;
        }
        api.addFtpServer(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        let data = {
          id: this.form.id,
          name: this.form.name,
        };
        api.editFtpServer(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("编辑成功");
            this.query();
          }
        });
      }
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteFtpServer(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    // 点击分享
    share(val) {
      this.shareId = val.id;
      this.searchIdList = [];
      //   this.oldSearchIdS = [];
      //   this.projectId = val.id;
      this.visibleShared = true;
      this.searchList = val.userInfoList;
      val.userInfoList.forEach((item) => {
        this.searchIdList.push(item.loginName);
        // this.oldSearchIdS.push(item.userId);
      });
    },
    // 选择分享xxx
    handleChange(val) {
      this.searchIdList = val;
    },
    // 取消分享
    sharedCancel() {
      this.visibleShared = false;
    },
    // 确定分享
    sharedOk() {
      //   console.log(this.searchIdList);
      let data = {
        id: this.shareId,
        loginNameList: this.searchIdList,
      };
      api.shareProject(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("操作成功！");
          this.query();
          this.sharedCancel();
        }
      });
    },
    // 关闭分享
    closeShared() {
      this.searchList = [];
    },
    // 搜索分享名称
    searchUserName(val) {
      if (val !== "") {
        //非空
        api.userList({ name: val }).then((res) => {
          if (res.result == 200) {
            this.searchList = res.data.userList;
          }
        });
      } else {
        //空值
        this.searchList = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
