<template>
  <div class="shareList">
    <a-table
      size="small"
      :components="$common.getTitle(columnsShare)"
      :columns="columnsShare"
      :dataSource="dataSourceShare"
      :pagination="paginationShare"
    >
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a href="javascript:;" @click="qrCode(record)">二维码</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="Copy(record)">复制连接</a>
      </span>
    </a-table>
    <a-modal
      title="二维码"
      v-model="visibleQrCode"
      :maskClosable="false"
      :after-close="qrCodeClose"
    >
      <div class="cotent">
        <div id="qrcode" ref="qrcode" class="qrcode"></div>
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="visibleQrCode = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
    <a-modal title="复制链接" v-model="visibleCopy" width="800px">
      <div>
        页面下载：<span class="hidden">{{ ModalText }}</span>
        <a-button type="primary" v-copy="ModalText">复制</a-button>
      </div>
      <div style="margin-top: 20px" v-if="needPassword">
        直接下载：<span class="hidden">{{ ModalUrl }}</span>
        <a-button type="primary" v-copy="ModalUrl">复制</a-button>
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="submit" type="primary" @click="visibleCopy = false"
            >关闭</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/shareList.js";
import QRCode from "qrcodejs2";
export default {
  name: "shareList",
  data() {
    return {
      columnsShare: [
        {
          title: "序号",
          dataIndex: "id",
          width: 60,
        },
        {
          title: "文件名称",
          dataIndex: "fileName",
          // scopedSlots: { customRender: "fileName" }
        },
        {
          title: "下载次数",
          dataIndex: "downloadTimes",
          // scopedSlots: { customRender: "fileName" }
        },
        {
          title: "过期时间",
          dataIndex: "expireDate",
          // scopedSlots: { customRender: "fileName" }
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          // scopedSlots: { customRender: "fileName" }
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 102,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      dataSourceShare: [],
      paginationShare: {},
      visibleQrCode: false,
      qrUrl: "",
      visibleCopy: false,
      ModalText: "",
      ModalUrl: "",
      needPassword: false,
    };
  },
  mounted() {
    this.query();
  },
  methods: {
    // 点击查询
    query() {
      let data = {
        pageNo: 1,
        pageSize: 10,
      };
      this.getShareList(data);
    },
    // 查询分享列表
    getShareList(data) {
      api.shareList(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item, index) => {
            item.key = index + 1;
            item.expireDate = this.$common.transformTime(item.expireDate);
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            item.url = `https://file.huiyu.org.cn/download#${item.uuid}/${item.checkUuid}`;
          });
          this.dataSourceShare = list;
          this.paginationShare = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.changePage(current),
          };
        }
      });
    },
    // 分页
    changePage(index) {
      let data = {
        pageNo: index,
        pageSize: 10,
      };
      this.getShareList(data);
    },
    // 点击生成二维码
    qrCode(val) {
      this.visibleQrCode = true;
      this.qrUrl = val.redirectDownloadUrl;
      this.$nextTick(() => {
        this.qrcode1();
      });
    },
    qrcode1() {
      let qrcode = new QRCode("qrcode", {
        width: 200, // 设置宽度，单位像素
        height: 200, // 设置高度，单位像素
        text: this.qrUrl, // 设置二维码内容或跳转地址
      });
    },
    qrCodeClose() {
      document.getElementById("qrcode").innerHTML = ""; //先清空之前生成的二维码
    },
    Copy(val) {
      this.needPassword = !val.needPassword;
      this.visibleCopy = true;
      this.ModalText = val.downloadUrl;
      this.ModalUrl = val.redirectDownloadUrl;
    },
  },
};
</script>
<style scoped lang="scss">
.cotent {
  position: relative;
  height: 210px;
  .qrcode {
    position: absolute;
    left: 50%;
    margin-left: -100px;
  }
}
.hidden {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 80%;
  margin-right: 10px;
}
</style>
