<template>
  <div style="height:100%">
    <a-layout id="components-layout-demo-custom-trigger">
      <a-layout-sider
        :trigger="null"
        collapsible
        v-model="collapsed"
        :collapsedWidth="isPc === true ? 80 : 0"
        :style="{
          overflow: 'auto',
          height: '100%',
          position: 'fixed',
          left: 0,
          zIndex: '1000',
        }"
      >
        <a-menu
          theme="dark"
          mode="inline"
          :selectedKeys="['/' + $route.path.split('/')[1]]"
          :defaultOpenKeys="openKeys"
          @click="selectClick"
          @openChange="onOpenChange"
        >
          <a-sub-menu v-for="item in list" :key="item.url">
            <span slot="title">
              <a-icon :type="item.iconType" />
              <span>{{ item.name }}</span>
            </span>
            <a-menu-item v-for="menu in item.children" :key="menu.url">
              {{ menu.name }}
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-sider>
      <a-layout-header
        :class="{
          isheader: !collapsed,
          onheader: collapsed,
          onheaderPc: isPcShow,
        }"
        style="background: #fff; padding: 0 30px 0 0; position: fixed; width: 100%; z-index: 1"
      >
        <a-icon
          class="trigger"
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="() => (collapsed = !collapsed)"
        />
        <span class="title-theme">文件管理系统</span>
        <a-dropdown
          style="display: inline-block; height: 100%; vertical-align: initial; float: right; min-width:100px"
        >
          <span
            style="cursor: pointer;border-left:1px solid #d3d3d3; padding-left: 10px; font-size: 16px;"
          >
            <a-avatar
              class="avatar"
              size="small"
              shape="circle"
              :src="loginAvatarUrl"
              v-if="loginAvatarUrl"
            />
            <span>{{ loginNickName }}</span>
          </span>
          <a-menu style="width: 150px" slot="overlay">
            <a-menu-item>
              <a
                href="javascript:void(0)"
                @click="logOut()"
                style="display:inline-block;text-align: center;width:100%;padding:0;margin:0"
              >
                <a-icon type="poweroff" />
                <span>退出登录</span>
              </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </a-layout-header>
      <a-layout
        :class="{
          isContent: !collapsed,
          onContent: collapsed,
          onContentPc: isPcShow,
        }"
        :style="{ padding: '24px 16px' }"
      >
        <div style="background:rgb(255, 255, 255);padding: 24px;">
          <router-view name="second" v-if="isComponent" />
        </div>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      isPc: false,
      collapsed: false,
      openKeys: [],
      rootSubmenuKeys: [],
      menuList: [],
      list: [
        {
          name: "文件管理",
          url: "1",
          iconType: "environment",
          children: [
            {
              name: "文件列表",
              url: "/fileList",
              iconType: "laptop",
            },
            {
              name: "分享列表",
              url: "/shareList",
              iconType: "laptop",
            },
            {
              name: "文件空间",
              url: "/fileSpace",
              iconType: "laptop",
            },
            {
              name: "vod图片管理",
              url: "/vodImageDirectory",
              iconType: "laptop",
            },
            {
              name: "oss管理",
              url: "/ossManagement",
              iconType: "laptop",
            },
            {
              name: "ftp管理",
              url: "/ftpAccount",
              iconType: "icon-ftp",
            },
          ],
        },
      ],
      loginAvatarUrl: "",
      loginNickName: "",
      orgPassword: "",
      newPassword: "",
      visibleUpdate: false,
      loading: false,
      url: "",
      isComponent: false,
    };
  },
  computed: {
    isPcShow() {
      if (this.collapsed && this.isPc) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    window.onresize = () => {
      this.IsPC();
    };
    this.IsPC();
    this.login();
  },
  methods: {
    // 判断手机or电脑端
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      this.isPc = flag; //true为PC端，false为手机端
      //手机端默认收起菜单
      if (!this.isPc) {
        this.collapsed = true;
      }
    },
    // 开启关闭导航栏
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    login() {
      //用户登录
      this.$http.get("/alpha/user/info").then((res) => {
        if (res.result == 200) {
          this.isComponent = true;
          this.loginNickName = res.userName;
          this.loginAvatarUrl = res.avatar;
          let url = window.location.href;
          url = url.split("/");
          this.list.forEach((item) => {
            item.children.forEach((menu) => {
              if (menu.url === "/" + url[3]) {
                this.openKeys.push(item.url);
              }
            });
            this.rootSubmenuKeys.push(item.url);
          });
        }
      });
    },
    //导航选择
    selectClick({ item, key, keyPath }) {
      this.$router.push(key);
      sessionStorage.setItem("pathHref", key);
    },
    //点击退出登录
    logOut() {
      this.$http.post("/alpha/user/logout").then((res) => {
        if (res.result == 200) {
          let url = window.location.href;
          url = url.split("/");
          url = url[0] + "//" + url[2];
          window.location.href = url;
        }
      });
    },
  },
};
</script>

<style>
.isheader {
  padding-left: 200px !important;
}
.onheaderPc {
  padding-left: 80px !important;
}
.onheader {
  transition: all 0.3s;
}
.isContent {
  margin-left: 200px;
  margin-top: 64px;
}
.onContent {
  margin-top: 64px;
  transition: all 0.3s;
}
.onContentPc {
  margin-left: 80px;
}
.ant-table-body {
  overflow: auto;
}
.ant-table th {
  white-space: nowrap;
}
.ant-table td {
  white-space: nowrap;
  position: relative;
}
#components-layout-demo-custom-trigger {
  height: 100%;
}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: all 0.3s;
}
#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}
#components-layout-demo-custom-trigger .logo {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
}
#components-layout-demo-custom-trigger .logo .logo-image {
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0.2);
  margin: 5px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
}
#components-layout-demo-custom-trigger .logo span {
  color: #ffffff;
}
.title-theme {
  display: inline-block;
  font-weight: bold;
  font-size: 18px;
}
.avatar {
  margin-right: 10px !important;
}
.logo .logo-title {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  font-size: 20px;
}
</style>
