import http from "./axios";

// 查询列表
const getDataNodeInfoList = () => {
  return http.get("/hadoop/getDataNodeInfoList");
};

export default {
  getDataNodeInfoList,
};
