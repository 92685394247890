<template>
  <div class="fileDownload">
    <div v-if="dataSourcShare.length < 1" class="empty">
      <a-empty />
    </div>
    <div class="downLoad-form" v-for="item in dataSourcShare" :key="item.id">
      <div class="file-content">
        <div class="file">
          <span class="file-title">{{ item.fileName }}</span>
          <a-divider type="vertical" />
        </div>
      </div>
      <div class="list">
        <div class="file">
          <span class="file-title">{{ item.gmtCreated }}</span>
          <a-divider type="vertical" />
        </div>
      </div>
      <div class="list">
        <div class="file">
          <span class="file-title">{{ item.fileSize }}</span>
          <a-divider type="vertical" />
        </div>
      </div>
      <div class="btn">
        <a
          href="javascript:;"
          class="btn-download"
          :disabled="item.expired"
          @click="dowmloadFile(item)"
          v-if="!item.expired"
        >
          下载
        </a>
        <a
          href="javascript:;"
          class="btn-download"
          :disabled="item.expired"
          v-if="item.expired"
        >
          已过期
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "../lib/fileDownload.js";

export default {
  name: "fileDownload",
  data() {
    return {
      dataSourcShare: [],
      progressNum: 0,
      isProgress: false,
      uuid: "",
      code: "",
      url: "",
    };
  },
  mounted() {
    this.url = location.href.split("?")[1];
    this.uuid = this.url.split("&")[0].split("=")[1];
    this.code = this.url.split("&")[1].split("=")[1];
    console.log(this.url);
    this.query();
  },
  methods: {
    // 点击查询
    query() {
      let data = {
        pageNo: 1,
        pageSize: 100,
        uuid: this.uuid,
        code: this.code,
      };
      this.getShareList(data);
    },
    // 查询分享列表
    getShareList(data) {
      api.shareList(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          // list.forEach((item, index) => {
          //   item.key = index + 1;
          //   // item.expireDate = this.$common.transformTime(item.expireDate);
          //   // item.gmtCreated = this.$common.transformTime(item.gmtCreated);
          //   item.url = `https://file.huiyu.org.cn/download#${item.uuid}/${item.checkUuid}`;
          // });
          this.dataSourcShare = list;
        }
      });
    },
    // 点击下载
    dowmloadFile(item) {
      let data = {
        spaceDetailId: item.id,
      };
      let url = "";
      for (let i in data) {
        if (data[i]) {
          url += i + "=" + data[i] + "&";
        }
      }
      let href =
        location.protocol +
        process.env.VUE_APP_BASE_API +
        "/fileSpace/downloadSpaceFile?" +
        url.substr(0, url.length - 1);
      window.location.href = href;
      this.isProgress = true;
      this.$http
        .get_progress("/fileSpace/downloadSpaceFile", data, this.progressBar)
        .then((res) => {});
    },
    // 获取百分比（此处保留了一位小数）
    progressBar(progressEvent) {
      let percentage = Number(
        ((progressEvent.loaded / progressEvent.total) * 100).toFixed(1)
      );
      this.progressNum = percentage;
      if (percentage === 100) {
        this.isDownload = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fileDownload {
  background-image: url(../assets/bg.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
  background-color: #f9f9fa;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  .empty {
    margin-top: 200px;
  }
  .file-content {
    // width: 480px;
    color: black;
    display: inline-block;
    margin-bottom: 10px;
    .file {
      position: relative;
      padding: 1px 0;
      border-radius: 4px;

      .file-title {
        font-size: 16px;
      }
    }
  }
  .list {
    display: inline-block;
    .file {
      position: relative;
      padding: 12px 0;
      border-radius: 4px;

      .file-title {
        font-size: 12px;
      }
    }
  }
  .btn {
    display: inline-block;
    .btn-download {
      font-size: 12px;
    }
  }
}
</style>
