import http from "./axios";

// 查询账号列表
export const ftpList = (data) => {
  return http.get("/ftpAccount/list", data);
};

// 新增账号
export const ftpAdd = (data) => {
  return http.json_post("/ftpAccount/addAccount", data);
};

// 编辑账号
export const editAccount = (data) => {
  return http.json_post("/ftpAccount/editAccount", data);
};

// 删除账号
export const ftpDelete = (data) => {
  return http.get("/ftpAccount/deleteAccount", data);
};

// 查询ftp服务器集合
export const serverList = (data) => {
  return http.get("/ftp/list", data);
};

// 获取密码
export const getPassword = (data) => {
  return http.get("/ftpAccount/copyInfo", data);
};
