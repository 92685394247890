<template>
  <div class="ossConfigList">
    <div class="searchCondition">
      <div class="searchConditionItem">
        <span>ossName：</span>
        <a-input
          placeholder="请输入"
          v-model.trim="ossName"
          @pressEnter="query(1)"
          allowClear
          @change="allowClearChange"
        />
      </div>
      <div class="searchConditionItem">
        <span>accessKeyId：</span>
        <a-input
          placeholder="请输入"
          v-model.trim="accessKeyId"
          @pressEnter="query(1)"
          allowClear
          @change="allowClearChange"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)">查询</a-button>
        <a-button type="primary" @click="add">新增</a-button>
      </div>
    </div>
    <a-table
      size="small"
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      :rowKey="(record) => record.id"
    >
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">是否确认删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      class="action-class"
      width="500px"
    >
      <a-form :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }">
        <a-form-item label="ossName:">
          <a-input v-model="updateData.ossName" placeholder="请输入" />
        </a-form-item>
        <a-form-item label="endPoint:">
          <a-input v-model="updateData.endPoint" placeholder="请输入" />
        </a-form-item>
        <a-form-item label="accessKeyId:">
          <a-input v-model="updateData.accessKeyId" placeholder="请输入" />
        </a-form-item>
        <a-form-item label="accessKeySecret:">
          <a-input v-model="updateData.accessKeySecret" placeholder="请输入" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/ossConfigList.js";

export default {
  name: "ossConfigList",
  data() {
    return {
      title: "",
      ossName: "",
      accessKeyId: "",
      groupId: "",
      addKeyVisible: false,
      updateData: {},
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "ossName",
          dataIndex: "ossName",
          ellipsis: true,
        },
        {
          title: "endPoint",
          dataIndex: "endPoint",
          ellipsis: true,
        },
        {
          title: "accessKeyId",
          dataIndex: "accessKeyId",
          ellipsis: true,
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          ellipsis: true,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query();
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        ossName: this.ossName,
        accessKeyId: this.accessKeyId,
      };
      this.loadingTable = true;
      api
        .ossConfigPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 修改
    modifyClick(val) {
      this.title = "修改";
      this.addKeyVisible = true;
      this.groupId = val.id;
      this.updateData = {
        ossName: val.ossName,
        endPoint: val.endPoint,
        accessKeyId: val.accessKeyId,
        accessKeySecret: val.accessKeySecret,
      };
    },
    // 新增
    add() {
      this.title = "新增";
      this.addKeyVisible = true;
      this.updateData = {
        ossName: "",
        endPoint: "",
        accessKeyId: "",
        accessKeySecret: "",
      };
    },
    // 确定新增
    add_submit() {
      let data = { ...this.updateData };
      if (this.title === "新增") {
        api.addData(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        data.id = this.groupId;
        api.editData(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("修改成功");
            this.query();
          }
        });
      }
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteData(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
