<template>
  <div class="accountFile">
    <div class="btn">
      <a-button @click="btnUpload" type="primary"> 上传文件 </a-button>
      <a-button @click="btnAddFile" type="primary"> 新建文件夹 </a-button>
    </div>
    <div class="tree">
      <ftp-tree-file
        ref="ftpTreeFile"
        :treeData="treeFileData"
        :loadUrl="loadUrl"
        :ftpAccountId="ftpAccountId"
        @onSelect="onSelect"
        @onExpand="onExpand"
        @onLoadData="onLoadData"
        @onRightClick="onRightClick"
      >
      </ftp-tree-file>
    </div>
    <v-contextmenu ref="contextmenu">
      <v-contextmenu-item @click="download"
        ><a-icon type="download" /> 下载文件</v-contextmenu-item
      >
      <v-contextmenu-item @click="share"
        ><a-icon type="share-alt" /> 分享</v-contextmenu-item
      >
      <v-contextmenu-item @click="deleteNode"
        ><a-icon type="delete" /> 删除文件</v-contextmenu-item
      >
      <v-contextmenu-item @click="rename"
        ><a-icon type="edit" /> 重命名</v-contextmenu-item
      >
    </v-contextmenu>
    <v-contextmenu ref="contextmenu1">
      <v-contextmenu-item @click="unpload"
        ><a-icon type="cloud-upload" /> 上传文件</v-contextmenu-item
      >
      <v-contextmenu-item @click="addFile"
        ><a-icon type="folder-add" /> 新建文件夹</v-contextmenu-item
      >
      <v-contextmenu-item @click="deleteNode"
        ><a-icon type="delete" /> 删除文件夹</v-contextmenu-item
      >
      <v-contextmenu-item @click="rename"
        ><a-icon type="edit" /> 重命名</v-contextmenu-item
      >
    </v-contextmenu>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      class="action-class"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="文件夹名:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="fileName" placeholder="请输入文件夹名" allowClear />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button key="submit" type="primary" @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 分享 -->
    <a-modal
      title="分享"
      v-model="shareVisible"
      :maskClosable="false"
      :afterClose="shareClose"
      class="action-class"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="密码:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="password" placeholder="请输入密码" />
          <a href="javascript:;" @click="randomPswAdd" class="randomPswAdd"
            >随机密码</a
          >
        </a-form-item>
        <a-form-item
          label="过期时间:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-date-picker
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            v-model="expirationTime"
            placeholder="过期时间"
            style="width: 100%"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="shareVisible = false">取消</a-button>
          <a-button key="submit" type="primary" @click="share_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 复制分享链接 -->
    <a-modal
      title="复制链接"
      v-model="copyVisible"
      :maskClosable="false"
      class="action-class"
      width="500px"
    >
      <a-textarea
        v-model="inviteCode"
        :auto-size="{ minRows: 3, maxRows: 5 }"
      />
      <a-button type="primary" v-copy="inviteCode" style="margin-top: 10px"
        >一键复制</a-button
      >
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="copyVisible = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 上传文件 -->
    <a-modal
      title="上传文件"
      v-model="uploadVisible"
      :maskClosable="false"
      :afterClose="uploadClose"
      class="action-class"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="上传路径:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="uploadFilePath" placeholder="请输入上传路径" />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="文件:"
        >
          <div class="dropbox">
            <a-upload-dragger
              :fileList="fileList"
              :remove="handleRemove"
              :beforeUpload="beforeUpload"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox" />
              </p>
              <p class="ant-upload-text">单击或拖动文件到此区域进行上传</p>
              <p class="ant-upload-hint">只支持单个上传</p>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="uploadVisible = false">取消</a-button>
          <a-button key="submit" type="primary" @click="addFile_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 新建文件夹 -->
    <a-modal
      title="新建文件夹"
      v-model="addFileVisible"
      :maskClosable="false"
      :afterClose="uploadClose"
      class="action-class"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="上传路径:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="uploadFilePath" placeholder="请输入上传路径" />
        </a-form-item>
        <a-form-item
          label="文件夹名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="uploadFileName" placeholder="请输入文件夹名称" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addFileVisible = false">取消</a-button>
          <a-button key="submit" type="primary" @click="addFileName_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import * as api from "../lib/accountFile.js";
import ftpTreeFile from "./ftpTreeFile.vue";

export default {
  name: "accountFile",
  components: {
    ftpTreeFile,
  },
  data() {
    return {
      shareVisible: false,
      password: "",
      expirationTime: "",
      copyVisible: false,
      inviteCode: "",
      uploadPath: location.protocol + process.env.VUE_APP_BASE_API,
      path: "",
      type: "",
      ftpAccountId: "",
      treeFileData: [],
      syncLoading: null,
      intervalTime: 0,
      selectKey: "",
      newTreeList: [],
      loadUrl: "/delivery/ftpFile/list",
      scrollY: 0,
      remoteFilePath: "",
      isDirectory: false,
      addKeyVisible: false,
      uploadVisible: false,
      addFileVisible: false,
      fileName: "",
      title: "",
      fileTitle: "",
      uploadFilePath: "",
      uploadFileName: "",
      fileList: [],
      uploadNode: "",
    };
  },
  mounted() {
    this.path = this.$route.query.path;
    this.ftpAccountId = this.$route.query.ftpAccountId;
    this.type = this.$route.query.type;
    this.getFtpFileList();
  },
  methods: {
    //随机密码-新增账号
    randomPswAdd() {
      let psw = this.$common.randomStr(false, 12);
      this.password = psw;
    },
    // 点击文件列表
    onSelect(info) {
      console.log("info", info);
      if (this.selectKey !== info.node.eventKey) {
        this.intervalTime = 0;
      }
      let newTime = new Date().getTime();
      let diff = newTime - this.intervalTime;
      this.intervalTime = newTime;
      this.selectKey = info.node.eventKey;
      // 判断双击
      if (diff > 800) {
        return;
      }
      this.selectKey = "";
      if (!info.node.dataRef.hasChildrenFile) {
        return;
      }
      this.newTreeList = [];
      this.newTreeList = this.$common.deepClone(this.treeFileData);
    },
    // 展开/收起节点时触发
    onExpand() {
      console.log("展开收起", this.expandedKeys);
    },
    // 获取文件目录列表
    getFtpFileList() {
      let data = {
        path: this.path,
        ftpAccountId: this.ftpAccountId,
        type: this.type,
      };
      this.syncLoading = this.$syncLoading();
      api
        .ftpFileList(data)
        .then((res) => {
          this.syncLoading.close();
          if (res.result === 200) {
            let list = res.data;
            let newArr = [];
            list.forEach((item) => {
              let obj = {
                title: item.name,
                key: "/" + item.name,
                icon: item.fileType === "directory" ? "folder" : "file",
                scopedSlots: { icon: "custom" },
                hasChildrenFile: item.fileType === "directory" ? true : false,
                isLeaf: item.fileType === "directory" ? false : true,
                filePath: [item.name],
                privilege: item.privilege.trim().split(" ")[0],
                fileLength: item.fileLength,
              };
              newArr.push(obj);
            });
            this.treeFileData = newArr;
            this.showRootFileList();
          }
        })
        .catch((err) => {
          this.syncLoading.close();
        });
    },
    // 动态加载
    onLoadData(treeNode, data) {
      let treeList = this.$common.deepClone(data);
      let newArr = [];
      let superiorNode = this.loopGetKeys(treeNode.eventKey, this.treeFileData);
      treeList.forEach((item) => {
        let obj = {
          title: item.name,
          key: treeNode.eventKey + "/" + item.name,
          icon: item.fileType === "directory" ? "folder" : "file",
          scopedSlots: { icon: "custom" },
          hasChildrenFile: item.fileType === "directory" ? true : false,
          isLeaf: item.fileType === "directory" ? false : true,
          filePath: [item.name],
          privilege: item.privilege.trim().split(" ")[0],
          fileLength: item.fileLength,
        };
        obj.filePath = [...superiorNode.filePath, ...obj.filePath];
        newArr.push(obj);
      });
      treeNode.dataRef.children = newArr;
      this.treeFileData = [...this.treeFileData];
    },
    // 获取点击列表info
    loopGetKeys(key, tree) {
      let res;
      for (let i = 0; i < tree.length; i++) {
        const item = tree[i];
        if (item.key === key) {
          res = item;
        } else {
          if (item.children && this.loopGetKeys(key, item.children)) {
            res = this.loopGetKeys(key, item.children);
          }
        }
      }
      return res;
    },
    // 异步更新数据

    // 右键点击
    onRightClick({ event, node }) {
      // console.log("右击", event, node);
      let newNode = node.eventKey.split("/");
      this.uploadNode = newNode.slice(0, newNode.length - 1).join("/");
      console.log("右击1", newNode, this.uploadNode);
      const x = event.x;
      const y = event.y;
      const postition = {
        top: y,
        left: x,
      };
      if (node.dataRef.isLeaf) {
        this.$refs.contextmenu.show(postition);
      } else {
        this.$refs.contextmenu1.show(postition);
      }
      // data
      this.remoteFilePath = node.dataRef.key;
      this.isDirectory = !node.dataRef.isLeaf;
      this.fileTitle = node.dataRef.title;
    },
    // 点击新建
    addFile() {
      this.title = "新建文件夹";
      this.addKeyVisible = true;
      this.fileName = "";
    },
    // 关闭新建
    addKeyClose() {
      this.fileName = "";
    },
    // 关闭上传
    uploadClose() {
      this.uploadFilePath = "";
      this.fileList = [];
      this.uploadFileName = "";
    },
    // 确定新建文件夹
    add_submit() {
      if (this.title === "新建文件夹") {
        let data = {
          parentDirPath: this.remoteFilePath,
          ftpAccountId: this.ftpAccountId,
          dirName: this.fileName,
          type: this.type,
        };
        api.mkdir(data).then((res) => {
          if (res.result === 200) {
            this.$message.success("新建成功");
            this.addKeyVisible = false;
          }
        });
      } else if (this.title === "重命名") {
        let data = {
          ftpAccountId: this.ftpAccountId,
          remoteFilePath: this.remoteFilePath,
          newName: this.fileName,
          type: this.type,
        };
        api.rename(data).then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.addKeyVisible = false;
          }
        });
      }
    },
    // 重命名
    rename() {
      this.title = "重命名";
      this.addKeyVisible = true;
      this.fileName = this.fileTitle;
    },
    // 下载文件
    download() {
      let href =
        this.uploadPath +
        "/delivery/ftpFile/download?" +
        "remoteFilePath=" +
        this.remoteFilePath +
        "&" +
        "ftpAccountId=" +
        this.ftpAccountId;
      window.location.href = href;
    },
    // 上传文件
    unpload() {
      this.uploadVisible = true;
      this.uploadFilePath = this.remoteFilePath;
    },
    // 确定上传
    addFile_submit() {
      let data = {
        file: this.fileList[0],
        filePath: this.remoteFilePath,
        ftpAccountId: this.ftpAccountId,
        type: this.type,
      };
      api.upload(data).then((res) => {
        if (res.result === 200) {
          this.uploadVisible = false;
          this.$message.success("上传成功");
        }
      });
    },
    btnUpload() {
      this.uploadVisible = true;
      this.uploadFilePath = "/";
    },
    // 根目录新建文件夹
    btnAddFile() {
      this.addFileVisible = true;
      this.uploadFilePath = "/";
      this.uploadFileName = "";
    },
    // 根目录新建文件夹确定
    addFileName_submit() {
      let data = {
        parentDirPath: this.uploadFilePath,
        ftpAccountId: this.ftpAccountId,
        dirName: this.uploadFileName,
        type: this.type,
      };
      api.mkdir(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("新建成功");
          this.addFileVisible = false;
        }
      });
    },
    // 上传文件数据处理
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      this.fileList = this.fileList.slice(-1);
      return false;
    },
    // 分享文件
    share() {
      this.shareVisible = true;
      var date1 = new Date();
      //今天时间
      var time1 =
        date1.getFullYear() +
        "-" +
        (date1.getMonth() + 1) +
        "-" +
        date1.getDate();
      var date2 = new Date(time1);
      date2.setDate(date1.getDate() + 7);

      var time2 =
        date2.getFullYear() +
        "-" +
        (date2.getMonth() + 1) +
        "-" +
        date2.getDate();
      this.expirationTime = time2;
    },
    shareClose() {
      this.password = "";
    },
    // 分享确定
    share_submit() {
      let data = {
        path: this.remoteFilePath,
        fileName: this.fileTitle,
        password: this.password,
        expirationTime: this.expirationTime,
        ftpAccountId: this.ftpAccountId,
        type: this.type,
      };
      api.getFileShareLink(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("分享成功");
          this.copyVisible = true;
          this.inviteCode = res.data.url;
          this.shareVisible = false;
        }
      });
    },
    // 删除
    deleteNode() {
      let data = {
        ftpAccountId: this.ftpAccountId,
        remoteFilePath: this.remoteFilePath,
        isDirectory: this.isDirectory,
        type: this.type,
      };
      this.$confirm({
        title: "确认删除",
        content: "是否删除该文件",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          api.ftpFileDelete(data).then((res) => {
            if (res.result === 200) {
              this.$message.success("删除成功");
            }
          });
        },
        onCancel() {},
        class: "test",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.accountFile {
  width: 100%;
  height: 100%;
  .btn {
    height: 40px;
    button {
      float: right;
      margin-left: 10px;
    }
  }
  .tree {
    width: 100%;
    height: 100%;
  }
}
.randomPswAdd {
  position: absolute;
  right: -66px;
  top: -10px;
}
</style>
