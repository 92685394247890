import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import antd from "../src/lib/antd";
import "ant-design-vue/dist/antd.css";
import http from "./lib/axios";
import axios from "axios";
import common from "./lib/common";
import VueClipboard from "vue-clipboard2";
import contentmenu from "v-contextmenu";
import "v-contextmenu/dist/index.css";
import syncLoading from "./lib/syncLoading.js"; // 引入loading
import VueDraggableResizable from "vue-draggable-resizable";

Vue.use(syncLoading); // 全局使用loading
Vue.use(contentmenu);
Vue.use(VueClipboard);
Vue.use(antd);
Vue.component("vue-draggable-resizable", VueDraggableResizable);

Vue.prototype.$http = http;
Vue.prototype.$common = common;
Vue.prototype.$axios = axios;

Vue.config.productionTip = false;

import Directives from "./directives";
Vue.use(Directives);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
