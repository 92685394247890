<template>
  <div class="vodImageDirectory">
    <div class="searchCondition">
      <div class="searchConditionItem">
        名称：
        <a-input
          placeholder="请输入名称"
          v-model.trim="name"
          @keyup.enter="query(1)"
          allowClear
          @change="allowClearChange"
        ></a-input>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      size="small"
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDateSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      :rowKey="(record) => record.id"
    >
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a-button type="link" @click="viewDetails(record)" style="padding: 0">
          文件列表
        </a-button>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="deleteClick(record)" style="color: red"
          >删除</a
        >
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :after-close="addKeyClose"
      class="action-class"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="nameAdd" placeholder="请输入名称" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <a-modal
      :title="detailTitle"
      v-model="visibleDetail"
      :maskClosable="false"
      :after-close="closeDetail"
      :width="1200"
    >
      <div class="searchCondition">
        <div class="searchConditionItem">
          标签：
          <a-input
            placeholder="请输入标签"
            v-model.trim="tags"
            @keyup.enter="getDetail(1)"
            allowClear
            @change="allowClearGetDetailChange"
          ></a-input>
        </div>
        <div class="searchConditionItem">
          文件名称：
          <a-input
            placeholder="请输入文件名称"
            v-model.trim="detailName"
            @keyup.enter="getDetail(1)"
            allowClear
            @change="allowClearGetDetailChange"
          ></a-input>
        </div>
        <div class="searchButton">
          <a-button type="primary" @click="getDetail(1)" icon="search"
            >查询</a-button
          >
          <a-button type="primary" @click="addDetail" icon="plus"
            >新增</a-button
          >
        </div>
      </div>
      <a-table
        size="small"
        :components="$common.getTitle(detailColumns)"
        :columns="detailColumns"
        :dataSource="detailDataSource"
        :pagination="detailPagination"
        :row-selection="{
          onChange: onSelectChange,
        }"
        :loading="loading"
        :rowKey="(record) => record.id"
      >
        <span
          slot="image"
          slot-scope="text, record"
          style="cursor: pointer"
          @click="bigImg(text)"
        >
          <img :src="text" alt="" style="width: 25px; height: 25px" />
        </span>
        <span slot="action" slot-scope="text, record">
          <a href="javascript:;" @click="copyUrl(record)" v-copy="copyInfo"
            >复制链接</a
          >
          <a-divider type="vertical" />
          <a
            href="javascript:;"
            @click="delete_fileClick(record)"
            style="color: red"
            >删除</a
          >
        </span>
      </a-table>
      <a-button type="danger" @click="deleteIds" :disabled="!hasSelected"
        >批量删除</a-button
      >
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="visibleDetail = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
    <a-modal
      title="新增文件"
      v-model="addDetailVisible"
      :maskClosable="false"
      :after-close="addDetailClose"
      class="action-class"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="标签:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="tagsAdd" placeholder="请输入标签" />
        </a-form-item>
        <a-form-item
          label="上传图片:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-upload-dragger
            :fileList="fileList"
            :remove="handleRemove"
            :beforeUpload="beforeUpload"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">单击或拖动文件到此区域进行上传</p>
            <p class="ant-upload-hint">只支持单个上传</p>
          </a-upload-dragger>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addDetailVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="addDetailSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <a-modal
      title="大图预览"
      v-model="bigImgVisible"
      :maskClosable="false"
      :after-close="bigImgClose"
      class="action-class"
      width="500px"
    >
      <div style="width: 100%; height: 450px">
        <img :src="bigImgSrc" alt="" style="width: 100%; height: 100%" />
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="bigImgVisible = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import api from "../lib/vodImageDirectory.js";
export default {
  name: "vodImageDirectory",
  data() {
    return {
      title: "",
      name: "",
      nameAdd: "",
      uuid: "",
      uuidAdd: "",
      groupId: "",
      addKeyVisible: false,
      addDetailVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
        },
        {
          title: "名称",
          dataIndex: "name",
        },
        {
          title: "uuid",
          dataIndex: "uuid",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 120,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDateSource: [],
      tablePagination: {},
      loadingTable: false,
      detailTitle: "",
      visibleDetail: false,
      detailColumns: [
        {
          title: "序号",
          dataIndex: "id",
          width: 60,
        },
        {
          title: "名称",
          dataIndex: "name",
        },
        {
          title: "图片",
          dataIndex: "imageUrl",
          scopedSlots: { customRender: "image" },
        },
        {
          title: "标签",
          dataIndex: "tags",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          width: 120,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      detailDataSource: [],
      detailPagination: {},
      detailPageNo: 1,
      directoryId: "",
      tags: "",
      detailName: "",
      tagsAdd: "",
      imageUrl: "",
      fileList: [],
      copyInfo: "",
      selectedRowKeys: [],
      ids: [],
      loading: false,
      bigImgVisible: false,
      bigImgSrc: "",
    };
  },
  mounted() {
    this.query(1);
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  methods: {
    bigImgClose() {
      this.bigImgSrc = "";
    },
    bigImg(val) {
      this.bigImgSrc = val;
      this.bigImgVisible = true;
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys.splice(0);
      selectedRows.forEach((item) => {
        this.selectedRowKeys.push(item.id);
      });
    },
    deleteIds() {
      this.$confirm({
        title: "确认删除",
        content: "是否删除文件",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          api.deleteImage({ ids: this.selectedRowKeys }).then((res) => {
            if (res.result == 200) {
              this.$message.success("删除成功");
              this.selectedRowKeys.splice(0);
              this.getDetail();
            }
          });
        },
        onCancel() {},
      });
    },
    delete_fileClick(val) {
      this.$confirm({
        title: "确认删除",
        content: "是否删除【" + val.name + "】文件",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          api.deleteImage({ ids: [val.id] }).then((res) => {
            if (res.result == 200) {
              this.$message.success("删除成功");
              this.getDetail();
            }
          });
        },
        onCancel() {},
      });
    },
    // 复制链接
    copyUrl(val) {
      this.copyInfo = val.imageUrl;
    },
    deleteClick(val) {
      this.$confirm({
        title: "确认删除",
        content: "是否删除【" + val.name + "】文件",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          api.deleteData({ id: val.id }).then((res) => {
            if (res.result == 200) {
              this.$message.success("删除成功");
              this.query();
            }
          });
        },
        onCancel() {},
      });
    },
    // 上传文件数据处理
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      this.fileList = this.fileList.slice(-1);
      return false;
    },
    addDetailSubmit() {
      if (this.fileList.length == 0) {
        this.$message.warning("请选择文件后再上传");
        return;
      }
      let data = {
        file: this.fileList[0],
        tags: this.tagsAdd,
        directoryId: this.directoryId,
      };
      api.addVodImage(data).then((res) => {
        if (res.result == 200) {
          this.addDetailVisible = false;
          this.$message.success("上传成功");
          this.getDetail(1);
        }
      });
    },
    addDetailClose() {
      this.tagsAdd = "";
      this.fileList.splice(0);
    },
    addDetail() {
      this.addDetailVisible = true;
    },
    allowClearGetDetailChange(e) {
      if (e.target.value) {
        return;
      }
      this.getDetail(1);
    },
    getDetail(index) {
      this.loading = true;
      if (index) {
        this.detailPageNo = index;
      }
      let data = {
        pageNo: index || this.detailPageNo,
        pageSize: 10,
        directoryId: this.directoryId,
        tags: this.tags,
        name: this.detailName,
      };
      api.getDetailList(data).then((res) => {
        if (res.result === 200) {
          this.detailDataSource = res.data.records;
          this.detailPagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.changePageDetail(current),
          };
          this.loading = false;
        }
      });
    },
    changePageDetail(index) {
      this.detailPageNo = index;
      this.getDetail(index);
    },
    viewDetails(val) {
      this.visibleDetail = true;
      this.detailTitle = "【" + val.name + "】文件列表详情";
      this.directoryId = val.id;
      this.selectedRowKeys.splice(0);
      this.getDetail(1);
    },
    closeDetail() {
      this.detailDataSource = [];
      this.tags = "";
      this.detailName = "";
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        name: this.name,
      };
      this.queryPage(data);
    },
    // 查询列表
    queryPage(data) {
      this.loadingTable = true;
      api
        .queryPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDateSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 修改
    modifyClick(val) {
      this.title = "修改";
      this.addKeyVisible = true;
      this.groupId = val.id;
      this.nameAdd = val.name;
    },
    // 新增
    add() {
      this.title = "新增";
      this.addKeyVisible = true;
      this.nameAdd = "";
    },
    // 确定新增
    add_submit() {
      let data = {
        name: this.nameAdd,
      };
      if (this.title === "新增") {
        api.addData(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        data.id = this.groupId;
        api.editData(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("修改成功");
            this.query();
          }
        });
      }
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.groupId = "";
      this.nameAdd = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.vodImageDirectory {
  background-color: #fff;
  padding: 20px;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
::v-deep .el-upload__input {
  display: none !important;
}
</style>
